import React, { Component } from 'react';
import {
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBRow,
  MDBTabContent,
  MDBTabPane,
  MDBBtn,
} from 'mdbreact';
import { connect } from 'react-redux';

class AdminForm extends Component {
  state = {
    activeItem: '1',
  };

  componentDidMount() {}

  toggle = tab => {
    const { activeItem } = this.state;
    if (activeItem !== tab) {
      this.setState({
        activeItem: tab,
      });
    }
  };

  render() {
    const { activeItem } = this.state;
    const { form, onSubmit, onChange } = this.props;
    const { title, description } = form.data;
    const { errors } = form;

    const { titleError, descriptionError } = errors;
    return (
      <form onSubmit={onSubmit} id="form">
        <MDBNav tabs className="my-3 dark-purple">
          <MDBNavItem>
            <MDBNavLink
              active={activeItem === '1'}
              onClick={e => {
                e.preventDefault();
                this.toggle('1');
              }}
              role="tab"
              to="/tab1"
            >
              <MDBIcon icon="database" /> Dane
            </MDBNavLink>
          </MDBNavItem>
        </MDBNav>
        <MDBTabContent activeItem={activeItem}>
          <MDBTabPane tabId="1" role="tabpanel">
            <MDBRow>
              <MDBCol lg={12}>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Tytuł"
                      name="title"
                      className={titleError === undefined ? '' : 'is-invalid'}
                      value={title}
                      type="textarea"
                      onChange={onChange}
                    >
                      {titleError !== undefined && (
                        <div className="invalid-feedback">{titleError[0]}</div>
                      )}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Zawartość"
                      name="description"
                      className={descriptionError === undefined ? '' : 'is-invalid'}
                      value={description}
                      type="textarea"
                      onChange={onChange}
                    >
                      {descriptionError !== undefined && (
                        <div className="invalid-feedback">{descriptionError[0]}</div>
                      )}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </MDBTabPane>
        </MDBTabContent>

        <MDBBtn type="submit">Zapisz</MDBBtn>
      </form>
    );
  }
}

AdminForm.defaultProps = {};

AdminForm.propTypes = {};

const mapStateToProps = state => {
  return {
    token: state.token,
  };
};

export default connect(mapStateToProps, {})(AdminForm);
