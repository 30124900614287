import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { LoginPage } from 'mdb-admin';
import { logInAction } from '../actions';

class Login extends React.Component {
  state = {
    email: '',
    password: '',
  };

  login = event => {
    event.preventDefault();
    const { logIn } = this.props;
    const { email, password } = this.state;
    logIn(email, password);
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  errorMessage() {
    const { errorMessage } = this.props;
    return errorMessage ? <div className="info-red">{errorMessage}</div> : <></>;
  }

  render() {
    const { email, password } = this.state;
    return (
      <>
        <LoginPage
          title="Logowanie"
          changeHandler={this.changeHandler}
          submit={this.login}
          email={email}
          password={password}
          errorMessage={this.errorMessage()}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.token,
    errorMessage: state.error,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    { logIn: logInAction },
  )(Login),
);
