import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  MDBBtn,
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBRow,
  MDBSelect,
  MDBTabContent,
  MDBTabPane,
  toast,
} from 'mdbreact';
import { Field, Form } from 'react-final-form';
import axios from 'axios';
import PanelTemplate from '../../templates/PanelTemplate';
import { mainURL } from '../../actions';

export const CreateCode = () => {
  const [activeItem, setActiveItem] = useState('1');
  const token = useSelector(state => state.token);
  const [redirect, setRedirect] = useState(null);
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    axios
      .get(`${mainURL}admin/partners`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        setPartners(data.partners);
      });
  }, [token]);

  return (
    <PanelTemplate>
      {redirect && <Redirect to={redirect} />}
      <h3 className="py-2 color-dark-purple">Dodaj kod</h3>
      <MDBNav tabs className="my-3 dark-purple">
        <MDBNavItem>
          <MDBNavLink
            active={activeItem === '1'}
            onClick={e => {
              e.preventDefault();
              setActiveItem('1');
            }}
            role="tab"
            to="/tab1"
          >
            <MDBIcon icon="database" /> Dane
          </MDBNavLink>
        </MDBNavItem>
      </MDBNav>
      <MDBTabContent activeItem={activeItem}>
        <MDBTabPane tabId="1" role="tabpanel">
          <MDBRow>
            <MDBCol lg={6}>
              <Form
                onSubmit={values => {
                  axios
                    .post(`${mainURL}admin/codes`, values, {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    })
                    .then(() => {
                      toast.success('Dodano kody.');
                      setRedirect(`/kody`);
                    });
                }}
                render={({ handleSubmit, submitting, form }) => (
                  <form onSubmit={handleSubmit}>
                    <Field
                      name="partner_id"
                      render={({ input }) => (
                        <MDBSelect
                          options={[
                            {
                              text: 'Brak',
                              value: '',
                              checked: input.value === '',
                            },
                            ...partners.map(selectItem => {
                              return {
                                text: `${selectItem.name} ${selectItem.surname} ${selectItem.organization}`,
                                value: selectItem.id,
                                checked: selectItem.id === input.value,
                              };
                            }),
                          ]}
                          selected="Wybierz partnera"
                          label="Wybierz partnera"
                          getValue={selectValue => {
                            if (input.value !== selectValue[0]) {
                              form.change(input.name, selectValue[0]);
                            }
                          }}
                        />
                      )}
                    />

                    <Field
                      name="code"
                      render={({ input, meta }) => (
                        <MDBInput
                          label="Kod"
                          className={meta.touched && meta.error ? 'is-invalid' : ''}
                          {...input}
                        >
                          {meta.touched && meta.error && (
                            <div className="invalid-feedback">{meta.error}</div>
                          )}
                        </MDBInput>
                      )}
                    />
                    <Field
                      name="count"
                      type="number"
                      render={({ input, meta }) => (
                        <MDBInput
                          label="Ilość kodów"
                          className={meta.touched && meta.error ? 'is-invalid' : ''}
                          {...input}
                        >
                          {meta.touched && meta.error && (
                            <div className="invalid-feedback">{meta.error}</div>
                          )}
                        </MDBInput>
                      )}
                    />
                    <Field
                      name="timesToUse"
                      type="number"
                      render={({ input, meta }) => (
                        <MDBInput
                          label="Ilość użyć"
                          className={meta.touched && meta.error ? 'is-invalid' : ''}
                          {...input}
                        >
                          {meta.touched && meta.error && (
                            <div className="invalid-feedback">{meta.error}</div>
                          )}
                        </MDBInput>
                      )}
                    />
                    <Field
                      name="discount"
                      type="number"
                      step="0.01"
                      render={({ input, meta }) => (
                        <MDBInput
                          label="Zniżka"
                          className={meta.touched && meta.error ? 'is-invalid' : ''}
                          {...input}
                        >
                          {meta.touched && meta.error && (
                            <div className="invalid-feedback">{meta.error}</div>
                          )}
                        </MDBInput>
                      )}
                    />
                    <Field
                      name="provision"
                      type="number"
                      step="0.01"
                      render={({ input, meta }) => (
                        <MDBInput
                          label="Prowizja"
                          className={meta.touched && meta.error ? 'is-invalid' : ''}
                          {...input}
                        >
                          {meta.touched && meta.error && (
                            <div className="invalid-feedback">{meta.error}</div>
                          )}
                        </MDBInput>
                      )}
                    />
                    <Field
                      name="activeTo"
                      type="date"
                      render={({ input, meta }) => (
                        <MDBInput
                          label="Aktywny do"
                          className={meta.touched && meta.error ? 'is-invalid' : ''}
                          {...input}
                        >
                          {meta.touched && meta.error && (
                            <div className="invalid-feedback">{meta.error}</div>
                          )}
                        </MDBInput>
                      )}
                    />

                    <MDBBtn type="submit" disabled={submitting}>
                      Zapisz
                    </MDBBtn>
                  </form>
                )}
              />
            </MDBCol>
          </MDBRow>
        </MDBTabPane>
      </MDBTabContent>
    </PanelTemplate>
  );
};
