import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

export default function(ComposedComponent) {
  class NotAuthentication extends Component {
    PropTypes = {
      router: PropTypes.object,
    };

    componentWillMount() {
      const { authenticated, history } = this.props;
      if (authenticated) {
        history.push('/panel/');
      }
    }

    componentWillUpdate(nextProps) {
      const { history } = this.props;
      if (nextProps.authenticated) {
        history.push('/panel/');
      }
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return { authenticated: !!state.token };
  }

  return connect(mapStateToProps)(NotAuthentication);
}
