import React, { useEffect, useState } from 'react';
import { MDBCol, MDBRow } from 'mdbreact';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { mainURL } from '../../actions';

export const Codes = ({ id }) => {
  const [codes, setCodes] = useState([]);
  const token = useSelector(state => state.token);

  useEffect(() => {
    axios
      .get(`${mainURL}admin/partners/${id}/codes`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data: { codes: newCodes } }) => {
        setCodes(newCodes);
      });
  }, [id, token]);

  return (
    <div>
      {codes.map(({ code, active, timesToUse, infinite, discount, provision }) => (
        <MDBRow>
          <MDBCol lg={12}>
            Kod: {code}, {active ? 'aktywny' : 'nieaktywny'},{' '}
            {infinite ? 'bez ograniczeń' : `pozostao: ${timesToUse} użyć`}, zniżka: {discount},
            prowizja: {provision}
          </MDBCol>
        </MDBRow>
      ))}
    </div>
  );
};
