import React, { Component } from 'react';
import { connect } from 'react-redux';
import { baseURL, logOutAction } from "../../actions";


class LogoutPage extends Component {

  componentDidMount() {
    this.logOut()
  }

  logOut = () => {
    const { onLogout, token } = this.props;
    onLogout(token);
    window.location.replace(`${baseURL}login`);
  };

  render() {
    return (
      <div>
      </div>
    );
  }
}

const mapStateToProps = ({ token }) => {
  return {
    token,
  };
};

export default connect(mapStateToProps, { onLogout: logOutAction })(LogoutPage);
