import React, { Component } from 'react';
import axios from 'axios';
import { DataTable, PageTitle } from 'mdb-admin';
import { MDBBtn, toast } from 'mdbreact';
import { connect } from 'react-redux';
import PanelTemplate from '../../templates/PanelTemplate';
import { deleteRecipeAction, mainURL } from '../../actions';
import hasAccess from '../../middlewares/hasAccess';

class RecipeTestList extends Component {
  state = {
    items: [],
  };

  componentDidMount() {
    this.fetch();
  }

  fetch = () => {
    const { token } = this.props;
    axios
      .get(`${mainURL}admin/recipes/test`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        const { recipes } = response.data;

        this.setState({
          items: recipes,
        });
      })
      .catch(() => {
        // console.error(error);
      });
  };

  delete = id => {
    const { deleteItem, token } = this.props;
    deleteItem(token, id).then(response => {
      this.toggle();
      if (response === 204) {
        toast.success('Usunięto przepis');
        this.fetch();
      } else {
        toast.error(response);
      }
    });
  };

  render() {
    const { items } = this.state;
    const data = {
      columns: [
        {
          label: 'namePL',
          field: 'namePL',
          sort: 'asc',
        },
        {
          label: 'nameEN',
          field: 'nameEN',
          sort: 'asc',
        },
        {
          label: 'Akcje',
          field: 'actions',
          sort: 'disabled',
        },
      ],
      rows: items.map(item => {
        const { id, namePL, nameEN } = item;
        return {
          namePL,
          nameEN,
          actions: (
            <>
              <MDBBtn
                title="Edytuj"
                as="a"
                href={`/przepisy/${id}`}
                className="btn btn-default Ripple-parent"
              >
                Edytuj
              </MDBBtn>
            </>
          ),
        };
      }),
    };

    return (
      <PanelTemplate pageTitle="">
        <PageTitle>Lista testowych przepisów</PageTitle>
        <DataTable data={data} />
      </PanelTemplate>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.token,
  };
};

export default connect(mapStateToProps, {
  deleteItem: deleteRecipeAction,
})(hasAccess(RecipeTestList, 'recipes-list'));
