import React, { Component } from 'react';
import { DataTable, PageTitle, NewResourceButton } from 'mdb-admin';
import { MDBBtn, MDBIcon, toast, MDBModal, MDBModalFooter, MDBModalHeader } from 'mdbreact';
import { connect } from 'react-redux';
import PanelTemplate from '../../templates/PanelTemplate';
import { deleteInfoAction, deleteVideoAction, mainURL } from "../../actions";
import hasAccess from '../../middlewares/hasAccess';
import axiosClient from "../../api/axiosClient";

class VideoList extends Component {
  state = {
    items: [],
    modal: false,
  };

  componentDidMount() {
    this.fetch();
  }

  fetch = () => {
    const { token } = this.props;
    axiosClient.get(`${mainURL}admin/videos`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        const { videos } = response.data;

        this.setState({
          items: videos,
        });
      })
      .catch(error => {
        console.error(error);
      });
  };

  delete = id => {
    const { deleteItem, token } = this.props;
    deleteItem(token, id).then(response => {
      this.toggle();
      if (response === 204) {
        toast.success('Usunięto film');
        this.fetch();
      } else {
        toast.error(response);
      }
    });
  };

  toggle = (modal = null) => {
    this.setState({
      modal,
    });
  };

  render() {
    const { items, modal } = this.state;
    const data = {
      columns: [
        {
          label: 'ID',
          field: 'id',
          sort: 'asc',
        },
        {
          label: 'Nazwa',
          field: 'title',
          sort: 'asc',
        },
        {
          label: 'Link',
          field: 'url',
          sort: 'asc',
        },
        {
          label: 'Akcje',
          field: 'actions',
          sort: 'disabled',
        },
      ],
      rows: items.map(item => {
        const {
          id,
          title,
          url
        } = item;
        return {
          id,
          title,
          url,
          actions: (
            <>
              <MDBBtn
                title="Edytuj"
                as="a"
                href={`/wideo/${id}`}
                className="btn btn-default Ripple-parent"
              >
                Edytuj
              </MDBBtn>

              <MDBBtn
                title="Usuń"
                pay
                onClick={() => {
                  this.toggle(id);
                }}
              >
                <MDBIcon icon="trash-alt" />
              </MDBBtn>
              <MDBModal isOpen={modal === id} toggle={this.toggle}>
                <MDBModalHeader toggle={this.toggle}>Usunąć?</MDBModalHeader>
                <MDBModalFooter>
                  <MDBBtn className="btn-default" onClick={this.toggle}>
                    Anuluj
                  </MDBBtn>
                  <MDBBtn
                    className="btn-default"
                    onClick={() => {
                      this.delete(id);
                    }}
                  >
                    Usuń
                  </MDBBtn>
                </MDBModalFooter>
              </MDBModal>
            </>
          ),
        };
      }),
    };

    return (
      <PanelTemplate pageTitle="">
        <PageTitle>Lista wideo</PageTitle>
        <NewResourceButton path="/wideo/dodaj">Dodaj</NewResourceButton>
        <DataTable data={data} />
      </PanelTemplate>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.token,
  };
};

export default connect(mapStateToProps, {
  deleteItem: deleteVideoAction,
})(hasAccess(VideoList, 'informations-list'));
