import React, { Component } from 'react';
import {
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBRow,
  MDBTabContent,
  MDBTabPane,
  MDBBtn,
  MDBSelect,
  MDBFileInput,
} from 'mdbreact';
import { connect } from 'react-redux';

class AdminForm extends Component {
  state = {
    activeItem: '1',
  };

  componentDidMount() {}

  toggle = tab => {
    const { activeItem } = this.state;
    if (activeItem !== tab) {
      this.setState({
        activeItem: tab,
      });
    }
  };

  render() {
    const { activeItem } = this.state;
    const { form, onSubmit, onChange, onChangeName } = this.props;
    const { titlePL, titleEN, titleSPA, range, category_id: categoryId, url } = form.data;
    const { errors } = form;

    const {
      titlePL: titlePL1,
      titleEN: titleEN1,
      titleSPA: titleSPA1,
      range: range1,
      category_id: categoryId1,
      photo: photo1,
    } = errors;
    return (
      <form onSubmit={onSubmit} id="form">
        <MDBNav tabs className="my-3 dark-purple">
          <MDBNavItem>
            <MDBNavLink
              active={activeItem === '1'}
              onClick={e => {
                e.preventDefault();
                this.toggle('1');
              }}
              role="tab"
              to="/tab1"
            >
              <MDBIcon icon="database" /> Dane
            </MDBNavLink>
          </MDBNavItem>
        </MDBNav>
        <MDBTabContent activeItem={activeItem}>
          <MDBTabPane tabId="1" role="tabpanel">
            <MDBRow>
              <MDBCol lg={6}>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Nazwa PL"
                      name="titlePL"
                      className={titlePL1 === undefined ? '' : 'is-invalid'}
                      value={titlePL}
                      type="textarea"
                      onChange={onChange}
                    >
                      {titlePL1 !== undefined && (
                        <div className="invalid-feedback">{titlePL1[0]}</div>
                      )}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Nazwa ENG"
                      name="titleEN"
                      className={titleEN1 === undefined ? '' : 'is-invalid'}
                      value={titleEN}
                      type="textarea"
                      onChange={onChange}
                    >
                      {titleEN1 !== undefined && (
                        <div className="invalid-feedback">{titleEN1[0]}</div>
                      )}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Nazwa SPA"
                      name="titleSPA"
                      className={titleSPA1 === undefined ? '' : 'is-invalid'}
                      value={titleSPA}
                      type="textarea"
                      onChange={onChange}
                    >
                      {titleSPA1 !== undefined && (
                        <div className="invalid-feedback">{titleSPA1[0]}</div>
                      )}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="range"
                      name="range"
                      className={range1 === undefined ? '' : 'is-invalid'}
                      value={range}
                      onChange={onChange}
                      type="number"
                    >
                      {range1 !== undefined && <div className="invalid-feedback">{range1[0]}</div>}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol>
                    <MDBSelect
                      options={[
                        {
                          text: 'Glukoza przed posiłkiem',
                          value: 1,
                          checked: categoryId === 1,
                        },
                        {
                          text: 'Glukoza po posiłku',
                          value: 2,
                          checked: categoryId === 2,
                        },
                        {
                          text: 'Co to jest A1C?',
                          value: 3,
                          checked: categoryId === 3,
                        },
                        {
                          text: 'WODA - porady i triki?',
                          value: 4,
                          checked: categoryId === 4,
                        },
                        {
                          text: 'Jak działa aplikacja ?',
                          value: 5,
                          checked: categoryId === 5,
                        },
                      ]}
                      selected="Wybierz kategorię"
                      label="Wybierz kategorię"
                      getValue={value => {
                        if (categoryId !== value[0]) {
                          if (value[0]) {
                            onChangeName(value[0], 'category_id');
                          }
                        }
                      }}
                    />
                    {categoryId1 !== undefined && (
                      <div className="invalid-feedback d-flex">{categoryId1[0]}</div>
                    )}
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol>
                    <MDBFileInput
                      getValue={value => {
                        onChangeName(value[0], 'photo');
                      }}
                    />

                    {photo1 !== undefined && (
                      <div className="invalid-feedback d-flex">{photo1[0]}</div>
                    )}
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol lg={6}>{url && <img src={url} alt="" className="w-100" />}</MDBCol>
            </MDBRow>
          </MDBTabPane>
        </MDBTabContent>

        <MDBBtn type="submit">Zapisz</MDBBtn>
      </form>
    );
  }
}

AdminForm.defaultProps = {};

AdminForm.propTypes = {};

const mapStateToProps = state => {
  return {
    token: state.token,
  };
};

export default connect(mapStateToProps, {})(AdminForm);
