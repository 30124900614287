import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { toast } from 'mdbreact';
import Form from './Form';
import { createProductAction } from '../../actions';
import PanelTemplate from '../../templates/PanelTemplate';
import hasAccess from "../../middlewares/hasAccess";

class ProductCreate extends Component {
  state = {
    form: {
      data: {
        micronutrient_product: [{ value: null, micronutrient_id: null }],
        unitOfMeasurePL:'g',
        unitOfMeasureEN:'g',
        unitOfMeasureSPA:'g',
      },
      errors: {},
    },
    redirect: null,
  };

  handleChange = e => {
    const { form } = this.state;
    const { name, value } = e.target;
    const newForm = { ...form };

    const splited = name.split('__');

    if (splited.length === 3) {
      if (newForm.data[splited[0]][splited[1]]) {
        newForm.data[splited[0]][splited[1]][splited[2]] = value;
      } else {
        newForm.data[splited[0]][splited[1]] = {};
      }
    } else {
      newForm.data[name] = value;
    }

    this.setState({ form: newForm });
  };

  handleChangeName = (value, name) => {
    const { form } = this.state;
    const newForm = { ...form };

    const splited = name.split('__');

    if (splited.length === 3) {
      if (newForm.data[splited[0]][splited[1]]) {
        newForm.data[splited[0]][splited[1]][splited[2]] = value;
      } else {
        newForm.data[splited[0]][splited[1]] = {};
      }
    } else {
      newForm.data[name] = value;
    }

    this.setState({ form: newForm });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { createItem, token } = this.props;
    const { form } = this.state;
    const { data } = form;

    const fd = new FormData();

    Object.keys(data).forEach(key => {
      console.log(data[key]);
      fd.append(key, data[key]);
    });

    createItem(token, data).then(response => {
      if (response.id) {
        toast.success('Dodano produkt.');
        setTimeout(() => {
          this.setState({ redirect: `/produkty` });
        }, 2000);
      } else {
        toast.error('Nie udało się dodać nowego produktu. Sprawdź błędy.');
        const newForm = { ...form };
        newForm.errors = response;

        this.setState({ form: newForm });
      }
    });
  };

  addMicronutrient = () => {
    const { form } = this.state;
    const { data } = form;
    const { micronutrient_product } = data;
    const newForm = { ...form };
    const newMicronutrientProduct = [...micronutrient_product];

    newMicronutrientProduct.push({
      value: null,
      micronutrient_id: null,
    });

    newForm.data.micronutrient_product = newMicronutrientProduct;
    this.setState({ form: newForm });
  };

  removeMicronutrient = itemIndex => {
    const { form } = this.state;
    const { data } = form;
    const { micronutrient_product } = data;
    const newForm = { ...form };

    const newMicronutrientProduct = micronutrient_product.filter((item, index) => {
      return index !== itemIndex;
    });

    newForm.data.micronutrient_product = newMicronutrientProduct;
    this.setState({ form: newForm });
  };

  render() {
    const { form, redirect, date } = this.state;
    if (redirect) {
      return <Redirect to={redirect} />;
    }

    return (
      <PanelTemplate>
        <h3 className="py-2 color-dark-purple">Dodaj produkt</h3>
        <Form
          form={form}
          date={date}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
          onChangeName={this.handleChangeName}
          onAddMicronutrient={this.addMicronutrient}
          onRemoveMicronutrient={this.removeMicronutrient}
        />
      </PanelTemplate>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.token,
  };
};

export default connect(
  mapStateToProps,
  {
    createItem: createProductAction,
  },
)(hasAccess(ProductCreate,"products-add"));
