import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
`;
const NotFound = () => {
  return (
    <StyledWrapper>
      <p>404</p>
      <p>Not Found</p>
    </StyledWrapper>
  );
};

export default NotFound;
