import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import MainTemplate from './templates/MainTemplate';
import Login from './views/Login';
import Manage from './views/Manage';
import requireAuth from './middlewares/require_auth';
import noRequireAuth from './middlewares/require_guest';
import MainPanel from './views/MainPanel';
import NotFound from './views/NotFound';
import UserList from './views/UserList';
import UserShow from './views/UserShow';
import AdminList from './views/Admins/List';
import AdminAdminCreate from './views/Admins/Create';
import AdminAdminEdit from './views/Admins/Edit';

import InformationList from './views/Informations/List';
import InformationCreate from './views/Informations/Create';
import InformationEdit from './views/Informations/Edit';

import RegulationList from './views/Regulations/List';
import RegulationEdit from './views/Regulations/Edit';

import VideoList from './views/Videos/List';
import VideoCreate from './views/Videos/Create';
import VideoEdit from './views/Videos/Edit';

import QuestionList from './views/Questions/List';
import QuestionCreate from './views/Questions/Create';
import QuestionEdit from './views/Questions/Edit';

import MicronutrientList from './views/Micronutrients/List';
import MicronutrientCreate from './views/Micronutrients/Create';
import MicronutrientEdit from './views/Micronutrients/Edit';

import ProductList from './views/Products/List';
import ProductCreate from './views/Products/Create';
import ProductEdit from './views/Products/Edit';

import RecipeList from './views/Recipes/List';
import RecipeCreate from './views/Recipes/Create';
import RecipeEdit from './views/Recipes/Edit';
import { AcceptList } from './views/Recipes/AcceptList';
import { PartnersList } from './views/Partners/PartnersList';
import { PartnerCreate } from './views/Partners/PartnerCreate';
import { PartnerEdit } from './views/Partners/PartnerEdit';
import { CodesList } from './views/PromoCodes/CodesList';
import { CreateCode } from './views/PromoCodes/CreateCode';
import RecipeTestList from './views/Recipes/RecipeTestList';
import { DietsList } from './views/Diets/DietsList';
import { DietCreate } from './views/Diets/DietCreate';
import { DietEdit } from './views/Diets/DietEdit';
import LogoutPage from './views/Logout';
import MedicinesList from './views/Medicines/List';
import MedicinesCreate from './views/Medicines/Create';
import MedicineEdit from './views/Medicines/Edit';
import CompilationList from './views/Complications/List';
import CompilationCreate from './views/Complications/Create';
import CompilationEdit from './views/Complications/Edit';

function Root() {
  return (
    <Provider store={store}>
      <MainTemplate>
        <Router>
          <Switch>
            <Route
              key="main"
              path="/"
              exact
              component={() => {
                return <Redirect to="/login" />;
              }}
            />
            <Route key="LOGIN" path="/login" exact component={noRequireAuth(Login)} />
            <Route key="Panel" path="/panel" exact component={requireAuth(MainPanel)} />
            <Route key="Diets" path="/diety" exact component={requireAuth(DietsList)} />
            <Route
              key="DietsCreate"
              path="/diety/dodaj"
              exact
              component={requireAuth(DietCreate)}
            />
            <Route key="DietsEdit" path="/diety/:id" exact component={requireAuth(DietEdit)} />
            <Route key="UserList" path="/uzytkownicy" exact component={requireAuth(UserList)} />
            <Route key="UserShow" path="/uzytkownicy/:id" exact component={requireAuth(UserShow)} />

            <Route
              key="AdminList"
              path="/administratorzy"
              exact
              component={requireAuth(AdminList)}
            />
            <Route
              key="AdminAdminCreate"
              path="/administratorzy/dodaj"
              exact
              component={requireAuth(AdminAdminCreate)}
            />
            <Route
              key="AdminAdminEdit"
              path="/administratorzy/:id"
              exact
              component={requireAuth(AdminAdminEdit)}
            />
            <Route
              key="PartnersList"
              path="/partnerzy"
              exact
              component={requireAuth(PartnersList)}
            />
            <Route
              key="PartnersCreate"
              path="/partnerzy/dodaj"
              exact
              component={requireAuth(PartnerCreate)}
            />
            <Route
              key="PartnersEdit"
              path="/partnerzy/:id"
              exact
              component={requireAuth(PartnerEdit)}
            />

            <Route key="CodesList" path="/kody" exact component={requireAuth(CodesList)} />
            <Route key="CreateCode" path="/kody/dodaj" exact component={requireAuth(CreateCode)} />

            <Route
              key="InformationList"
              path="/informacje"
              exact
              component={requireAuth(InformationList)}
            />
            <Route
              key="InformationCreate"
              path="/informacje/dodaj"
              exact
              component={requireAuth(InformationCreate)}
            />
            <Route
              key="InformationEdit"
              path="/informacje/:id"
              exact
              component={requireAuth(InformationEdit)}
            />

            <Route
              key="RegulationList"
              path="/regulaminy"
              exact
              component={requireAuth(RegulationList)}
            />
            {/* <Route
              key="RegulationCreate"
              path="/regulaminy/dodaj"
              exact
              component={requireAuth(RegulationCreate)}
            /> */}
            <Route
              key="RegulationEdit"
              path="/regulaminy/:id"
              exact
              component={requireAuth(RegulationEdit)}
            />

            <Route key="InformationList" path="/wideo" exact component={requireAuth(VideoList)} />
            <Route
              key="InformationCreate"
              path="/wideo/dodaj"
              exact
              component={requireAuth(VideoCreate)}
            />
            <Route
              key="InformationEdit"
              path="/wideo/:id"
              exact
              component={requireAuth(VideoEdit)}
            />

            <Route key="QuestionList" path="/pytania" exact component={requireAuth(QuestionList)} />
            <Route
              key="QuestionCreate"
              path="/pytania/dodaj"
              exact
              component={requireAuth(QuestionCreate)}
            />
            <Route
              key="QuestionEdit"
              path="/pytania/:id"
              exact
              component={requireAuth(QuestionEdit)}
            />

            <Route key="MedicinesList" path="/leki" exact component={requireAuth(MedicinesList)} />

            <Route
              key="MedicinesList"
              path="/leki/dodaj"
              exact
              component={requireAuth(MedicinesCreate)}
            />

            <Route
              key="MedicinesList"
              path="/leki/:id"
              exact
              component={requireAuth(MedicineEdit)}
            />

            <Route
              key="CompilationList"
              path="/powiklania"
              exact
              component={requireAuth(CompilationList)}
            />

            <Route
              key="CompilationList"
              path="/powiklania/dodaj"
              exact
              component={requireAuth(CompilationCreate)}
            />

            <Route
              key="CompilationList"
              path="/powiklania/:id"
              exact
              component={requireAuth(CompilationEdit)}
            />

            <Route
              key="MicronutrientList"
              path="/mikroskładniki"
              exact
              component={requireAuth(MicronutrientList)}
            />
            <Route
              key="MicronutrientCreate"
              path="/mikroskładniki/dodaj"
              exact
              component={requireAuth(MicronutrientCreate)}
            />
            <Route
              key="MicronutrientEdit"
              path="/mikroskładniki/:id"
              exact
              component={requireAuth(MicronutrientEdit)}
            />

            <Route key="ProductList" path="/produkty" exact component={requireAuth(ProductList)} />
            <Route
              key="ProductCreate"
              path="/produkty/dodaj"
              exact
              component={requireAuth(ProductCreate)}
            />
            <Route
              key="ProductEdit"
              path="/produkty/:id"
              exact
              component={requireAuth(ProductEdit)}
            />

            <Route key="RecipeList" path="/przepisy" exact component={requireAuth(RecipeList)} />
            <Route
              key="RecipeList"
              path="/przepisy/do-akceptacji"
              exact
              component={requireAuth(AcceptList)}
            />
            <Route
              key="RecipeCreate"
              path="/przepisy/dodaj"
              exact
              component={requireAuth(RecipeCreate)}
            />
            <Route
              key="TestList"
              path="/przepisy/testowe"
              exact
              component={requireAuth(RecipeTestList)}
            />
            <Route
              key="RecipeEdit"
              path="/przepisy/:id"
              exact
              component={requireAuth(RecipeEdit)}
            />

            <Route key="Manage" path="/Manage" exact component={requireAuth(Manage)} />

            <Route key="logout" path="/logout" exact component={LogoutPage} />
            <Route key="NotFound" path="*" exact component={NotFound} />
          </Switch>
        </Router>
      </MainTemplate>
    </Provider>
  );
}

export default Root;
