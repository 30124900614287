import React, { Component } from 'react';
import {
  MDBBtn,
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBModal,
  MDBModalFooter,
  MDBModalHeader,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBRow,
  MDBTabContent,
  MDBTabPane,
  toast,
} from 'mdbreact';
import axios from 'axios';
import { DataTable, PageTitle } from 'mdb-admin';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import PanelTemplate from '../templates/PanelTemplate';
import { mainURL } from '../actions';

class UserShow extends Component {
  state = {
    activeItem: '1',
    user: {
      parameters: {},
      meals: [],
    },
    recipes: [],
    modal: false,
    bmr:null,
  };

  componentDidMount() {
    const { match, token } = this.props;

    axios
      .get(`${mainURL}admin/users/${match.params.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        const { user, recipes, bmr } = response.data;
        console.log(recipes);
        this.setState({
          user,
          recipes,
          bmr
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  handleSubmit = values => {
    const { match, token } = this.props;

    axios
      .post(`${mainURL}admin/users/${match.params.id}`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        toast.success('Zaktualizowano dane');
      })
      .catch(() => {
        toast.error('Nie udało się zapisać zmian. Sprawdź błędy.');
      });
  };

  toggle = tab => {
    const { activeItem } = this.state;
    if (activeItem !== tab) {
      this.setState({
        activeItem: tab,
      });
    }
  };

  toggleModal = (modal = null) => {
    this.setState({
      modal,
    });
  };

  render() {
    const { user, activeItem, recipes, modal, bmr } = this.state;
    const {
      lang,
      email,
      diagnosis,
      age,
      points,
      bg_before: bgBefore,
      bg_after: bgAfter,
      gender,
      parameters,
      meals,
    } = user;
    const { weight, height, waist, hips, thigh, figure, a1c, morningBg } = parameters[0] || {};
    const [firstRecipe] = recipes;

    const data = {
      columns: [
        {
          label: 'ID',
          field: 'id',
          sort: 'asc',
        },
        {
          label: 'Numer posiłku',
          field: 'meal_number',
          sort: 'asc',
        },
        {
          label: 'namePL',
          field: 'namePL',
          sort: 'asc',
        },
        {
          label: 'Przepis tłuszcz różnica',
          field: 'recipe_fat',
          sort: 'asc',
        },
        {
          label: 'Przepis tłuszcz',
          field: 'recipe_fat_value',
          sort: 'asc',
        },
        {
          label: 'Przepis tłuszcz X',
          field: 'recipe_others_fat_value',
          sort: 'asc',
        },
        {
          label: 'Przepis białko różnica',
          field: 'recipe_proteins',
          sort: 'asc',
        },
        {
          label: 'Przepis białko',
          field: 'recipe_protein_value',
          sort: 'asc',
        },
        {
          label: 'Przepis białko X',
          field: 'recipe_others_proteins_value',
          sort: 'asc',
        },
        {
          label: 'Przepis węglowodany różnica',
          field: 'recipe_carbohydrates',
          sort: 'asc',
        },
        {
          label: 'Przepis węglowodany',
          field: 'recipe_carbohydrates_value',
          sort: 'asc',
        },
        {
          label: 'Przepis węglowodany X',
          field: 'recipe_others_carbohydrates_value',
          sort: 'asc',
        },
        {
          label: 'Tłuszcz na danie',
          field: 'fatForDish',
          sort: 'asc',
        },
        {
          label: 'Białko na danie',
          field: 'proteinsForDish',
          sort: 'asc',
        },
        {
          label: 'Węglowodany na danie',
          field: 'carbohydratesForDish',
          sort: 'asc',
        },
        {
          label: 'Węglowodany faktor obliczony',
          field: 'carbohydratesFactorCalc',
          sort: 'asc',
        },
        {
          label: 'Białko faktor obliczony',
          field: 'proteinsFactorCalc',
          sort: 'asc',
        },
        {
          label: 'Tłuszcz faktor obliczony',
          field: 'fatFactorCalc',
          sort: 'asc',
        },
        {
          label: 'Akcje',
          field: 'actions',
          sort: 'disabled',
        },
      ],
      rows: recipes.map(item => {
        const {
          id,
          meal_number,
          namePL,
          recipe_fat,
          recipe_fat_value,
          recipe_others_fat_value,
          recipe_proteins,
          recipe_protein_value,
          recipe_others_proteins_value,
          recipe_carbohydrates,
          recipe_carbohydrates_value,
          recipe_others_carbohydrates_value,
          fatForDish,
          proteinsForDish,
          carbohydratesForDish,
          carbohydratesFactorCalc,
          proteinsFactorCalc,
          fatFactorCalc,
          recipe_products,
        } = item;
        return {
          id,
          meal_number,
          namePL,
          recipe_fat,
          recipe_fat_value,
          recipe_others_fat_value,
          recipe_proteins,
          recipe_protein_value,
          recipe_others_proteins_value,
          recipe_carbohydrates,
          recipe_carbohydrates_value,
          recipe_others_carbohydrates_value,
          fatForDish,
          proteinsForDish,
          carbohydratesForDish,
          carbohydratesFactorCalc,
          proteinsFactorCalc,
          fatFactorCalc,
          actions: (
            <>
              <MDBBtn
                title="Edytuj"
                onClick={() => {
                  this.toggleModal(id);
                }}
                className="btn btn-default Ripple-parent"
              >
                Zobacz produkty
              </MDBBtn>
              <MDBModal key={id} isOpen={modal === id} toggle={this.toggleModal}>
                <MDBModalHeader toggle={this.toggleModal}>Lista składników</MDBModalHeader>
                <MDBModalFooter>
                  <div className="modal--content">
                    {recipe_products.map(product => (
                      <p>
                        {product.product.name}: {product.value} {product.product.unitOfMeasure}
                      </p>
                    ))}
                  </div>
                </MDBModalFooter>
              </MDBModal>
            </>
          ),
        };
      }),
    };
    return (
      <PanelTemplate pageTitle="Panel">
        <PageTitle>Użytkownik</PageTitle>

        <MDBNav tabs className="my-3 dark-purple">
          <MDBNavItem>
            <MDBNavLink
              active={activeItem === '1'}
              onClick={e => {
                e.preventDefault();
                this.toggle('1');
              }}
              role="tab"
              to="/tab1"
            >
              <MDBIcon icon="database" /> Dane
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBNavLink
              active={activeItem === '2'}
              onClick={e => {
                this.toggle('2');
                e.preventDefault();
              }}
              role="tab"
              to="/tab2"
            >
              <MDBIcon icon="database" /> Glukoza
            </MDBNavLink>
          </MDBNavItem>

          <MDBNavItem>
            <MDBNavLink
              active={activeItem === '3'}
              onClick={e => {
                e.preventDefault();
                this.toggle('3');
              }}
              role="tab"
              to="/tab3"
            >
              <MDBIcon icon="database" /> Współczynniki
            </MDBNavLink>
          </MDBNavItem>

          <MDBNavItem>
            <MDBNavLink
              active={activeItem === '4'}
              onClick={e => {
                e.preventDefault();
                this.toggle('4');
              }}
              role="tab"
              to="/tab4"
            >
              <MDBIcon icon="database" /> Wygenerowane dania dla użytkownika
            </MDBNavLink>
          </MDBNavItem>
        </MDBNav>

        <MDBTabContent activeItem={activeItem}>
          <MDBTabPane tabId="1" role="tabpanel">
            <MDBRow>
              <MDBCol>Email: {email}</MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>Język: {lang}</MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>Diagnoza: {diagnosis}</MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>Wiek: {age}</MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>Punkty: {points}</MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>Glukoza przed posiłkiem testowym: {bgBefore}</MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>Glukoza po posiłku testowym: {bgAfter}</MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>gender: {gender === 0 ? 'Kobieta' : 'Mężczyzna'}</MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol>Waga: {weight}</MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol>Wzrost: {height}</MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol>talia: {waist}</MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol>biodra: {hips}</MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol>uda: {thigh}</MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol>Typ figury: {figure}</MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol>A1C: {a1c}</MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>Poranna glukoza: {morningBg}</MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol>*************************</MDBCol>
            </MDBRow>
            {bmr && (
              <>
                <MDBRow>
                  <MDBCol>BMR: {bmr.BMR}</MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>Idealne BMR: {bmr.idealBMR}</MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>Idealna waga: {bmr.idealWeight}</MDBCol>
                </MDBRow>
              </>
            )}
          </MDBTabPane>

          <MDBTabPane tabId="2" role="tabpanel">
            {meals.map(item => {
              const {
                bg_before: bgBeforeItem,
                bg_after: bgAfterItem,
                hour,
                created_at: createdAt,
              } = item;
              return (
                <MDBRow>
                  <MDBCol>
                    data: {createdAt.substr(0, 10)} {hour}
                  </MDBCol>
                  <MDBCol>Glukoza przed posiłkiem: {bgBeforeItem}</MDBCol>
                  <MDBCol>Glukoza po posiłku: {bgAfterItem}</MDBCol>
                </MDBRow>
              );
            })}
          </MDBTabPane>
          <MDBTabPane tabId="3" role="tabpanel">
            <Form
              onSubmit={this.handleSubmit}
              // validate={validate}
              initialValues={{ ...user }}
              render={({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                  <MDBRow>
                    <MDBCol lg={6}>
                      <MDBRow>
                        <MDBCol>
                          <Field
                            name="brmWeightFactor"
                            type="number"
                            label="brmWeightFactor"
                            step="0.01"
                            render={({ input, meta, ...rest }) => (
                              <MDBInput
                                className={meta.touched && meta.error ? 'is-invalid' : ''}
                                {...input}
                                {...rest}
                              >
                                {meta.touched && meta.error && (
                                  <div className="invalid-feedback">{meta.error}</div>
                                )}
                              </MDBInput>
                            )}
                          />
                          <Field
                            name="brmHeightFactor"
                            type="number"
                            label="brmHeightFactor"
                            step="0.01"
                            render={({ input, meta, ...rest }) => (
                              <MDBInput
                                className={meta.touched && meta.error ? 'is-invalid' : ''}
                                {...input}
                                {...rest}
                              >
                                {meta.touched && meta.error && (
                                  <div className="invalid-feedback">{meta.error}</div>
                                )}
                              </MDBInput>
                            )}
                          />
                          <Field
                            name="brmAgeFactor"
                            type="number"
                            label="brmAgeFactor"
                            step="0.01"
                            render={({ input, meta, ...rest }) => (
                              <MDBInput
                                className={meta.touched && meta.error ? 'is-invalid' : ''}
                                {...input}
                                {...rest}
                              >
                                {meta.touched && meta.error && (
                                  <div className="invalid-feedback">{meta.error}</div>
                                )}
                              </MDBInput>
                            )}
                          />
                          <Field
                            name="brmConst"
                            type="number"
                            label="brmConst"
                            step="0.01"
                            render={({ input, meta, ...rest }) => (
                              <MDBInput
                                className={meta.touched && meta.error ? 'is-invalid' : ''}
                                {...input}
                                {...rest}
                              >
                                {meta.touched && meta.error && (
                                  <div className="invalid-feedback">{meta.error}</div>
                                )}
                              </MDBInput>
                            )}
                          />
                          <Field
                            name="brmFactor"
                            type="number"
                            label="brmFactor"
                            step="0.01"
                            render={({ input, meta, ...rest }) => (
                              <MDBInput
                                className={meta.touched && meta.error ? 'is-invalid' : ''}
                                {...input}
                                {...rest}
                              >
                                {meta.touched && meta.error && (
                                  <div className="invalid-feedback">{meta.error}</div>
                                )}
                              </MDBInput>
                            )}
                          />
                          <Field
                            name="ct2"
                            type="number"
                            label="ct2"
                            step="0.01"
                            render={({ input, meta, ...rest }) => (
                              <MDBInput
                                className={meta.touched && meta.error ? 'is-invalid' : ''}
                                {...input}
                                {...rest}
                              >
                                {meta.touched && meta.error && (
                                  <div className="invalid-feedback">{meta.error}</div>
                                )}
                              </MDBInput>
                            )}
                          />
                          <Field
                            name="weightFactor"
                            type="number"
                            label="weightFactor"
                            step="0.01"
                            render={({ input, meta, ...rest }) => (
                              <MDBInput
                                className={meta.touched && meta.error ? 'is-invalid' : ''}
                                {...input}
                                {...rest}
                              >
                                {meta.touched && meta.error && (
                                  <div className="invalid-feedback">{meta.error}</div>
                                )}
                              </MDBInput>
                            )}
                          />
                          <Field
                            name="idealBrmConst"
                            type="number"
                            label="idealBrmConst"
                            step="0.01"
                            render={({ input, meta, ...rest }) => (
                              <MDBInput
                                className={meta.touched && meta.error ? 'is-invalid' : ''}
                                {...input}
                                {...rest}
                              >
                                {meta.touched && meta.error && (
                                  <div className="invalid-feedback">{meta.error}</div>
                                )}
                              </MDBInput>
                            )}
                          />
                          <Field
                            name="idealBrmWeightFactor"
                            type="number"
                            label="idealBrmWeightFactor"
                            step="0.01"
                            render={({ input, meta, ...rest }) => (
                              <MDBInput
                                className={meta.touched && meta.error ? 'is-invalid' : ''}
                                {...input}
                                {...rest}
                              >
                                {meta.touched && meta.error && (
                                  <div className="invalid-feedback">{meta.error}</div>
                                )}
                              </MDBInput>
                            )}
                          />
                          <Field
                            name="idealBrmHeightFactor"
                            type="number"
                            label="idealBrmHeightFactor"
                            step="0.01"
                            render={({ input, meta, ...rest }) => (
                              <MDBInput
                                className={meta.touched && meta.error ? 'is-invalid' : ''}
                                {...input}
                                {...rest}
                              >
                                {meta.touched && meta.error && (
                                  <div className="invalid-feedback">{meta.error}</div>
                                )}
                              </MDBInput>
                            )}
                          />
                          <Field
                            name="idealBrmAgeFactor"
                            type="number"
                            label="idealBrmAgeFactor"
                            step="0.01"
                            render={({ input, meta, ...rest }) => (
                              <MDBInput
                                className={meta.touched && meta.error ? 'is-invalid' : ''}
                                {...input}
                                {...rest}
                              >
                                {meta.touched && meta.error && (
                                  <div className="invalid-feedback">{meta.error}</div>
                                )}
                              </MDBInput>
                            )}
                          />
                          <Field
                            name="proteinFactor"
                            type="number"
                            label="proteinFactor"
                            step="0.01"
                            render={({ input, meta, ...rest }) => (
                              <MDBInput
                                className={meta.touched && meta.error ? 'is-invalid' : ''}
                                {...input}
                                {...rest}
                              >
                                {meta.touched && meta.error && (
                                  <div className="invalid-feedback">{meta.error}</div>
                                )}
                              </MDBInput>
                            )}
                          />
                          <Field
                            name="fatFactor"
                            type="number"
                            label="fatFactor"
                            step="0.01"
                            render={({ input, meta, ...rest }) => (
                              <MDBInput
                                className={meta.touched && meta.error ? 'is-invalid' : ''}
                                {...input}
                                {...rest}
                              >
                                {meta.touched && meta.error && (
                                  <div className="invalid-feedback">{meta.error}</div>
                                )}
                              </MDBInput>
                            )}
                          />
                          <Field
                            name="correctionFactor"
                            type="number"
                            label="correctionFactor"
                            step="0.01"
                            render={({ input, meta, ...rest }) => (
                              <MDBInput
                                className={meta.touched && meta.error ? 'is-invalid' : ''}
                                {...input}
                                {...rest}
                              >
                                {meta.touched && meta.error && (
                                  <div className="invalid-feedback">{meta.error}</div>
                                )}
                              </MDBInput>
                            )}
                          />
                          <Field
                            name="waterFactor"
                            type="number"
                            label="waterFactor"
                            step="0.01"
                            render={({ input, meta, ...rest }) => (
                              <MDBInput
                                className={meta.touched && meta.error ? 'is-invalid' : ''}
                                {...input}
                                {...rest}
                              >
                                {meta.touched && meta.error && (
                                  <div className="invalid-feedback">{meta.error}</div>
                                )}
                              </MDBInput>
                            )}
                          />

                          <MDBBtn type="submit" disabled={submitting}>
                            {submitting ? '' : 'Zapisz'}
                          </MDBBtn>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                </form>
              )}
            />
          </MDBTabPane>

          <MDBTabPane tabId="4" role="tabpanel">
            <div>
              {firstRecipe && (
                <>
                  <p>Waga: {firstRecipe.weight}</p>
                  <p>BRM: {firstRecipe.bmrWithCorrection}</p>
                  <p>Liczba dań: {firstRecipe.dishesCount}</p>
                  <p>Tłuszcz: {firstRecipe.fat}</p>
                  <p>Białko: {firstRecipe.proteins}</p>
                  <p>Węglowodany: {firstRecipe.carbohydrates}</p>
                </>
              )}
            </div>
            <DataTable data={data} />
          </MDBTabPane>
        </MDBTabContent>
      </PanelTemplate>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.token,
  };
};

export default connect(mapStateToProps, {})(UserShow);
