import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MenuTemplate } from 'mdb-admin';
import { MDBNavLink, MDBSideNavCat, MDBSideNavNav, ToastContainer } from 'mdbreact';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { logOutAction } from '../actions';
import Luco from '../img/LUCO.png';

const StyledMenuTemplate = styled(MenuTemplate)`
  overflow-y: hidden;
`;

const SideNav = (
  <MDBSideNavNav>
    <MDBSideNavCat name="Użytkownicy" id="users" icon="users" userLink="user">
      <MDBNavLink to="/uzytkownicy" exact>
        Lista
      </MDBNavLink>
    </MDBSideNavCat>
    <MDBSideNavCat name="Administratorzy" id="users" icon="users" userLink="admins">
      <MDBNavLink to="/administratorzy" exact>
        Lista
      </MDBNavLink>
      <MDBNavLink to="/administratorzy/dodaj">Dodaj</MDBNavLink>
    </MDBSideNavCat>
    <MDBSideNavCat name="Partnerzy" id="partners" icon="users" userLink="partners">
      <MDBNavLink to="/partnerzy" exact>
        Lista
      </MDBNavLink>
      <MDBNavLink to="/partnerzy/dodaj">Dodaj</MDBNavLink>
    </MDBSideNavCat>
    <MDBSideNavCat name="Informacje" id="info" icon="info" userLink="info">
      <MDBNavLink to="/informacje" exact>
        Lista
      </MDBNavLink>
      <MDBNavLink to="/informacje/dodaj">Dodaj</MDBNavLink>
    </MDBSideNavCat>

    <MDBSideNavCat name="Wideo" id="info" icon="info" userLink="info">
      <MDBNavLink to="/wideo" exact>
        Lista
      </MDBNavLink>
      <MDBNavLink to="/wideo/dodaj">Dodaj</MDBNavLink>
    </MDBSideNavCat>

    <MDBSideNavCat name="Pytania" id="question" icon="question" userLink="question">
      <MDBNavLink to="/pytania" exact>
        Lista
      </MDBNavLink>
      <MDBNavLink to="/pytania/dodaj">Dodaj</MDBNavLink>
    </MDBSideNavCat>
    {/* <MDBSideNavCat name="Mikroskładniki" id="question" icon="question" userLink="micronutrients">
      <MDBNavLink to="/mikroskładniki">Lista</MDBNavLink>
      <MDBNavLink to="/mikroskładniki/dodaj">Dodaj</MDBNavLink>
    </MDBSideNavCat> */}
    <MDBSideNavCat name="Produkty" id="question" icon="apple-alt" userLink="micronutrients">
      <MDBNavLink to="/produkty" exact>
        Lista
      </MDBNavLink>
      <MDBNavLink to="/produkty/dodaj">Dodaj</MDBNavLink>
    </MDBSideNavCat>
    <MDBSideNavCat name="Kody" id="codes" icon="apple-alt" userLink="codes">
      <MDBNavLink to="/kody" exact>
        Lista
      </MDBNavLink>
      <MDBNavLink to="/kody/dodaj">Dodaj</MDBNavLink>
    </MDBSideNavCat>
    <MDBSideNavCat name="Przepisy" id="question" icon="file-alt" userLink="micronutrients">
      <MDBNavLink to="/przepisy" exact>
        Lista
      </MDBNavLink>
      <MDBNavLink to="/przepisy/do-akceptacji">Lista do akceptacji</MDBNavLink>
      <MDBNavLink to="/przepisy/dodaj">Dodaj</MDBNavLink>
      <MDBNavLink to="/przepisy/testowe">Przepisy testowe</MDBNavLink>
    </MDBSideNavCat>

    <MDBSideNavCat name="Diety" id="diets" icon="apple-alt" userLink="diets">
      <MDBNavLink to="/diety" exact>
        Lista
      </MDBNavLink>
      <MDBNavLink to="/diety/dodaj">Dodaj</MDBNavLink>
    </MDBSideNavCat>

    <MDBSideNavCat name="Leki" id="medicines" icon="notes-medical" userLink="medicines">
      <MDBNavLink to="/leki" exact>
        Lista
      </MDBNavLink>
      <MDBNavLink to="/leki/dodaj">Dodaj</MDBNavLink>
    </MDBSideNavCat>

    <MDBSideNavCat name="Powikłania" id="compilations" icon="hospital" userLink="compilations">
      <MDBNavLink to="/powiklania" exact>
        Lista
      </MDBNavLink>
      <MDBNavLink to="/powiklania/dodaj">Dodaj</MDBNavLink>
    </MDBSideNavCat>

    <MDBSideNavCat name="Regulaminy" id="regulations" icon="info" userLink="regulations">
      <MDBNavLink to="/regulaminy" exact>
        Lista
      </MDBNavLink>
    </MDBSideNavCat>
  </MDBSideNavNav>
);

class PanelTemplate extends Component {
  logOut = () => {
    const { onLogout, token } = this.props;
    onLogout(token);
  };

  render() {
    const { children, pageTitle } = this.props;
    return (
      <StyledMenuTemplate
        sideNav={SideNav}
        pageTitle={pageTitle}
        userUrl="/uzytkownik"
        logOut={this.logOut}
        logo={Luco}
      >
        {children}
        <ToastContainer hideProgressBar newestOnTop />
      </StyledMenuTemplate>
    );
  }
}

PanelTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  pageTitle: PropTypes.string.isRequired,
};

const mapStateToProps = ({ token }) => {
  return {
    token,
  };
};

export default connect(mapStateToProps, { onLogout: logOutAction })(PanelTemplate);
