import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "mdbreact";
import { getAdminAction, updateAdminAction } from "../../actions";
import ServiceForm from "./Form";
import PanelTemplate from "../../templates/PanelTemplate";
import hasAccess from "../../middlewares/hasAccess";


class AdminAdminEdit extends Component {
  state = {
    itemId: 0,
    initialValues: {}
  };

  componentDidMount(): void {
    this.fetch();
  }

  fetch = () => {
    const { match, getItem, token } = this.props;
    this.setState({
      itemId: match.params.id
    });

    getItem(token, match.params.id).then(item => {
      this.setState({
        initialValues: {
          ...item,
          permissions: [...item.permissions.map(({ details }) => details.map(({ id }) => id))].reduce((a, b) => a.concat(b))
        }
      });
    });
  };



  handleSubmit = values => {
    const { updateItem, token } = this.props;
    const { itemId } = this.state;

    updateItem(token, itemId, values).then(response => {
      if (response.id > 0) {
        toast.success("Zaktualizowano dane");
        this.fetch();
      } else {
        toast.error("Nie udało się zapisać zmian. Sprawdź błędy.");
      }
    });
  };

  render() {
    const { initialValues } = this.state;

    return (
      <PanelTemplate pageTitle="">
        <h3 className="py-2 color-dark-purple">Edytuj administratora</h3>
        <ServiceForm
          onSubmit={this.handleSubmit}
          isEditing
          initialValues={initialValues}
        />
      </PanelTemplate>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.token
  };
};

export default connect(
  mapStateToProps,
  {
    getItem: getAdminAction,
    updateItem: updateAdminAction
  }
)(hasAccess(AdminAdminEdit,"admins-edit"));
