import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`


*,*::before,*::after{
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}
html{}
body{font-family: 'Lato', sans-serif;}
`;

export default GlobalStyles;
