import { LOGIN, LOGOUT, FETCH_ERROR } from '../actions';

const initialState = {
  token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
  error: '',
  permissions:localStorage.getItem('permissions') ? localStorage.getItem('permissions') : '',
};
const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      localStorage.setItem('token', action.token);
      localStorage.setItem('permissions', action.permissions);
      return { ...state, token: action.token,permissions:action.permissions, };
    case FETCH_ERROR:
      return { ...state, error: action.payload };
    case LOGOUT:
      localStorage.removeItem('token');
      return { ...state, token: '' };
    default:
      return state;
  }
};

export default rootReducer;
