import React from 'react';
import { MDBCol, MDBInput, MDBRow } from 'mdbreact';
import { Field } from 'react-final-form';

export const CommentData = () => {
  return (
    <MDBRow>
      <MDBCol lg={4}>
        <Field
          name="drinks"
          type="textarea"
          label="Napoje w diecie"
          render={({ input, meta, ...rest }) => (
            <MDBInput
              className={meta.touched && meta.error ? 'is-invalid ' : ''}
              containerClass=""
              {...input}
              {...rest}
            >
              {meta.touched && meta.error && <div className="invalid-feedback">{meta.error}</div>}
            </MDBInput>
          )}
        />

        <Field
          name="vegetables"
          type="textarea"
          label="Warzywa w diecie"
          render={({ input, meta, ...rest }) => (
            <MDBInput
              className={meta.touched && meta.error ? 'is-invalid ' : ''}
              containerClass=""
              {...input}
              {...rest}
            >
              {meta.touched && meta.error && <div className="invalid-feedback">{meta.error}</div>}
            </MDBInput>
          )}
        />

        <Field
          name="permissibleAdditives"
          type="textarea"
          label="Dodatki dopuszczalne"
          render={({ input, meta, ...rest }) => (
            <MDBInput
              className={meta.touched && meta.error ? 'is-invalid ' : ''}
              containerClass=""
              {...input}
              {...rest}
            >
              {meta.touched && meta.error && <div className="invalid-feedback">{meta.error}</div>}
            </MDBInput>
          )}
        />
      </MDBCol>
      <MDBCol lg={4}>
        <Field
          name="contraindications"
          type="textarea"
          label="Przeciwwskazania"
          render={({ input, meta, ...rest }) => (
            <MDBInput
              className={meta.touched && meta.error ? 'is-invalid ' : ''}
              containerClass=""
              {...input}
              {...rest}
            >
              {meta.touched && meta.error && <div className="invalid-feedback">{meta.error}</div>}
            </MDBInput>
          )}
        />

        <Field
          name="physicalActivity"
          type="textarea"
          label="Aktywność fizyczna"
          render={({ input, meta, ...rest }) => (
            <MDBInput
              className={meta.touched && meta.error ? 'is-invalid ' : ''}
              containerClass=""
              {...input}
              {...rest}
            >
              {meta.touched && meta.error && <div className="invalid-feedback">{meta.error}</div>}
            </MDBInput>
          )}
        />

        <Field
          name="summary"
          type="textarea"
          label="Raportowanie i podsumowania"
          render={({ input, meta, ...rest }) => (
            <MDBInput
              className={meta.touched && meta.error ? 'is-invalid ' : ''}
              containerClass=""
              {...input}
              {...rest}
            >
              {meta.touched && meta.error && <div className="invalid-feedback">{meta.error}</div>}
            </MDBInput>
          )}
        />
      </MDBCol>
    </MDBRow>
  );
};
