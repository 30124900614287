import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { toast } from "mdbreact";
import VideoForm from "./Form";
import { createInfoAction, createVideoAction } from "../../actions";
import PanelTemplate from "../../templates/PanelTemplate";
import hasAccess from "../../middlewares/hasAccess";

class VideoCreate extends Component {
  state = {
    form: {
      data: {},
      errors: {}
    },
    redirect: null
  };

  handleChange = e => {
    const { form } = this.state;
    const { name, value } = e.target;
    const newForm = { ...form };

    newForm.data[name] = value;

    this.setState({ form: newForm });
  };


  handleSubmit = e => {
    e.preventDefault();
    const { createItem, token } = this.props;
    const { form } = this.state;
    const { data } = form;

    const fd = new FormData();

    Object.keys(data).forEach(key => {
      fd.append(key, data[key]);
    });


    createItem(token, fd).then(response => {
      if (response?.id) {
        toast.success("Dodano informację.");
        setTimeout(() => {
          this.setState({ redirect: `/wideo` });
        }, 2000);
      } else {
        toast.error("Nie udało się dodać nowego filmu. Sprawdź błędy.");
        const newForm = { ...form };
        newForm.errors = response;

        this.setState({ form: newForm });
      }
    });
  };



  render() {
    const { form, redirect, date } = this.state;
    if (redirect) {
      return <Redirect to={redirect}/>;
    }

    return (
      <PanelTemplate>
        <h3 className="py-2 color-dark-purple">Dodaj Wideo</h3>
        <VideoForm
          form={form}
          date={date}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
        />
      </PanelTemplate>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.token
  };
};

export default connect(
  mapStateToProps,
  {
    createItem: createVideoAction
  }
)(hasAccess(VideoCreate,"informations-add"));
