import axios from "axios";
// eslint-disable-next-line import/no-cycle
import { baseURL } from "../actions";

const axiosClient = axios.create({
  headers: {
    "content-type": "application/x-www-form-urlencoded",
  }
});

axiosClient.interceptors.response.use(undefined, function (error) {
  if(error.response.status === 401) {
    window.location.replace(`${baseURL}logout`);
  }
});

export default axiosClient;
