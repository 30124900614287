import React, { useState } from 'react';
import { PageTitle } from 'mdb-admin';
import { Form } from 'react-final-form';
import axios from 'axios';
import {
  MDBBtn,
  MDBIcon,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBTabContent,
  MDBTabPane,
  toast,
} from 'mdbreact';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PanelTemplate from '../../templates/PanelTemplate';
import { PatientData } from './PatientData';
import { mainURL } from '../../actions';

export const DietCreate = () => {
  const { token } = useSelector(state => state);
  const [activeItem, setActiveItem] = useState('1');
  const [redirect, setRedirect] = useState(null);
  return (
    <>
      {redirect && <Redirect to={redirect} />}
      <PanelTemplate>
        <PageTitle>Dodaj dietę</PageTitle>
        <Form
          onSubmit={values => {
            axios
              .post(`${mainURL}admin/diets`, values, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .then(
                ({
                  data: {
                    diet: { id },
                  },
                }) => {
                  toast.success('Zapisano dane');
                  setRedirect(`/diety/${id}`);
                },
              )
              .catch(() => {
                toast.error('Błąd - spróbuj ponownie');
              });
          }}
          initialValues={{
            sex: 1,
          }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <MDBNav tabs className="my-3 dark-purple">
                <MDBNavItem>
                  <MDBNavLink
                    active={activeItem === '1'}
                    onClick={e => {
                      e.preventDefault();
                      setActiveItem('1');
                    }}
                    role="tab"
                    to="/tab1"
                  >
                    <MDBIcon icon="database" /> Dane
                  </MDBNavLink>
                </MDBNavItem>
              </MDBNav>
              <MDBTabContent activeItem={activeItem}>
                <MDBTabPane tabId="1" role="tabpanel">
                  <PatientData />
                </MDBTabPane>
              </MDBTabContent>
              <MDBBtn type="submit">Zapisz</MDBBtn>
            </form>
          )}
        />
      </PanelTemplate>
    </>
  );
};
