import React from 'react';

import PanelTemplate from '../templates/PanelTemplate';

const MainPanel = () => {
  return (
    <PanelTemplate pageTitle="Panel">
      <h1>Witaj w panelu</h1>
    </PanelTemplate>
  );
};

export default MainPanel;
