import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "mdbreact";
import { getMicronutrientAction, updateMicronutrientAction } from "../../actions";
import MicronutrientForm from "./Form";
import PanelTemplate from "../../templates/PanelTemplate";
import hasAccess from "../../middlewares/hasAccess";


class MicronutrientEdit extends Component {
  state = {
    form: {
      data: {},
      errors: {}
    },
    itemId: 0
  };

  componentDidMount(): void {
    this.fetch();
  }

  fetch = () => {
    const { match, getItem, token } = this.props;
    this.setState({
      itemId: match.params.id
    });

    getItem(token, match.params.id).then(item => {
      const { form } = this.state;
      const newForm = { ...form };

      Object.keys(item).forEach((key) => {
        if (key === "photo") {
          newForm.data.url = item[key];
        }
        else if (key !== "account") {
          newForm.data[key] = item[key];
        }


      });

      this.setState({ form: newForm });
    });
  };

  handleChange = e => {
    const { form } = this.state;
    const { name, value } = e.target;
    const newForm = { ...form };

    const splited = name.split("__");


    if (splited.length === 3) {
      if (newForm.data[splited[0]][splited[1]]) {
        newForm.data[splited[0]][splited[1]][splited[2]] = value;
      } else {
        newForm.data[splited[0]][splited[1]] = {};
      }
    } else {
      newForm.data[name] = value;
    }

    this.setState({ form: newForm });
  };



  handleSubmit = e => {
    e.preventDefault();
    const { updateItem, token } = this.props;
    const { form, itemId } = this.state;
    const { data } = form;

    const fd = new FormData();

    Object.keys(data).forEach(key => {
      fd.append(key, data[key]);
    });

    fd.append('_method','PUT');


    updateItem(token, itemId, data).then(response => {
      if (response.id > 0) {
        toast.success("Zaktualizowano dane");
        const newForm = { ...form };
        newForm.data.newServices = [];
        this.setState({ form: newForm });
        this.fetch();
      } else {
        toast.error("Nie udało się zapisać zmian. Sprawdź błędy.");
        const newForm = { ...form };
        newForm.errors = response;
        this.setState({ form: newForm });
      }
    });
  };

  render() {
    const { form } = this.state;

    return (
      <PanelTemplate pageTitle="">
        <h3 className="py-2 color-dark-purple">Edytuj mikroskładnik</h3>
        <MicronutrientForm
          form={form}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
          edit
        />
      </PanelTemplate>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.token
  };
};

export default connect(
  mapStateToProps,
  {
    getItem: getMicronutrientAction,
    updateItem: updateMicronutrientAction
  }
)(hasAccess(MicronutrientEdit,"micronutrients-edit"));
