import React, { useEffect } from 'react';
import { toast } from 'mdbreact';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

export const HasAccess = permission => {
  const history = useHistory();
  const permissions = useSelector(state => state.permissions);
  useEffect(() => {
    if (!permissions.includes(permission)) {
      toast.warn('Nie masz dostępu do tego zasobu');
      history.push('/');
    }
  }, [history, permissions, permission]);

  return <></>;
};
