import React, { Component } from "react";
import axios from "axios";
import { DataTable, PageTitle } from "mdb-admin";
import { connect } from "react-redux";
import PanelTemplate from "../templates/PanelTemplate";
import { deleteUserAction, mainURL } from "../actions";
import { MDBBtn, MDBIcon, MDBModal, MDBModalFooter, MDBModalHeader, toast } from "mdbreact";
import axiosClient from "../api/axiosClient";

class UserList extends Component {

  state = {
    users: [],
    modal: false
  };


  componentDidMount() {
    this.fetch();
  }

  fetch = () => {
    const { token } = this.props;
    axiosClient.get(`${mainURL}admin/users`, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
      .then(response => {
        const { users } = response.data;
        console.log(users);

        this.setState({
          users
        });
      })
      .catch(error => {
        console.error(error);
      });
  };

  delete = id => {
    const { deleteItem, token } = this.props;
    deleteItem(token, id)
      .then(response => {
        this.toggle();
        if (response === 204) {
          toast.success("Usunięto użytkownika");
          this.fetch();
        } else {
          toast.error(response);
        }
      });
  };


  toggle = (modal = null) => {
    this.setState({
      modal
    });
  };

  render() {
    const { users, modal } = this.state;
    const data = {
      columns: [
        {
          label: "ID",
          field: "id",
          sort: "asc"
        },
        {
          label: "Email",
          field: "email",
          sort: "asc"
        },
        {
          label: "Język",
          field: "lang",
          sort: "asc"
        },
        {
          label: "Aktywny",
          field: "active",
          sort: "asc"
        },
        {
          label: "Wiek",
          field: "age",
          sort: "asc"
        },
        {
          label: "Punkty",
          field: "points",
          sort: "asc"
        },
        {
          label: "Płeć",
          field: "gender",
          sort: "asc"
        },
        {
          label: "BG przed posiłkiem",
          field: "bg_before",
          sort: "asc"
        },
        {
          label: "BG po posiłku",
          field: "bg_after",
          sort: "asc"
        },
        {
          label: "Akcje",
          field: "actions",
          sort: "disabled"
        }
      ],
      rows: users.map((item) => {
        const { id, email, lang, active, age, points, gender, bg_before, bg_after } = item;
        return {
          id,
          email,
          lang,
          active: (active ? "tak" : "nie"),
          age,
          points,
          gender: (gender === 0 ? "Kobieta" : "Mężczyzna"),
          bg_before,
          bg_after,
          actions: <>
            <MDBBtn title="Edytuj" as="a" href={`/uzytkownicy/${id}`}
                    className="btn btn-default Ripple-parent">
              <MDBIcon icon="eye"/>
            </MDBBtn>


            <MDBBtn title="Usuń" pay onClick={() => {
              this.toggle(id);
            }}><MDBIcon icon="trash-alt"/></MDBBtn>
            <MDBModal isOpen={modal === id} toggle={this.toggle}>
              <MDBModalHeader toggle={this.toggle}>Usunąć?</MDBModalHeader>
              <MDBModalFooter>
                <MDBBtn className="btn-default" onClick={this.toggle}>Anuluj</MDBBtn>
                <MDBBtn className="btn-default" onClick={() => {
                  this.delete(id);
                }}>Usuń</MDBBtn>
              </MDBModalFooter>
            </MDBModal>
          </>
        };
      })
    };

    return (
      <PanelTemplate pageTitle="Panel">
        <PageTitle>Lista użytkowników</PageTitle>
        <DataTable data={data}/>
      </PanelTemplate>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.token
  };
};

export default connect(mapStateToProps, {
  deleteItem: deleteUserAction
})(UserList);
