import React, { Component } from 'react';
import { DataTable, PageTitle } from 'mdb-admin';
import { MDBBtn, toast, MDBModal, MDBModalFooter, MDBModalHeader } from 'mdbreact';
import { connect } from 'react-redux';
import PanelTemplate from '../../templates/PanelTemplate';
import { deleteRegulationAction, mainURL } from '../../actions';
import hasAccess from '../../middlewares/hasAccess';
import axiosClient from '../../api/axiosClient';

class RegulationList extends Component {
  state = {
    items: [],
    modal: false,
  };

  componentDidMount() {
    this.fetch();
  }

  fetch = () => {
    const { token } = this.props;
    axiosClient
      .get(`${mainURL}admin/regulation`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        const { regulations } = response.data;

        this.setState({
          items: regulations,
        });
      })
      .catch(error => {
        console.error(error);
      });
  };

  delete = id => {
    const { deleteItem, token } = this.props;
    deleteItem(token, id).then(response => {
      this.toggle();
      if (response === 204) {
        toast.success('Usunięto regulamin');
        this.fetch();
      } else {
        toast.error(response);
      }
    });
  };

  toggle = (modal = null) => {
    this.setState({
      modal,
    });
  };

  render() {
    const { items, modal } = this.state;
    const data = {
      columns: [
        {
          label: 'ID',
          field: 'id',
          sort: 'asc',
        },
        {
          label: 'title',
          field: 'title',
          sort: 'asc',
        },
        {
          label: 'Utworzono',
          field: 'createdAt',
          sort: 'asc',
        },
        {
          label: 'Modyfikowano',
          field: 'updatedAt',
          sort: 'asc',
        },
        {
          label: 'Akcje',
          field: 'actions',
          sort: 'disabled',
        },
      ],
      rows: items.map(item => {
        const { id, title, created_at: createdAt, updated_at: updatedAt } = item;
        return {
          id,
          title,
          createdAt,
          updatedAt,
          actions: (
            <>
              <MDBBtn
                title="Edytuj"
                as="a"
                href={`/regulaminy/${id}`}
                className="btn btn-default Ripple-parent"
              >
                Edytuj
              </MDBBtn>
              <MDBModal isOpen={modal === id} toggle={this.toggle}>
                <MDBModalHeader toggle={this.toggle}>Usunąć?</MDBModalHeader>
                <MDBModalFooter>
                  <MDBBtn className="btn-default" onClick={this.toggle}>
                    Anuluj
                  </MDBBtn>
                  <MDBBtn
                    className="btn-default"
                    onClick={() => {
                      this.delete(id);
                    }}
                  >
                    Usuń
                  </MDBBtn>
                </MDBModalFooter>
              </MDBModal>
            </>
          ),
        };
      }),
    };

    return (
      <PanelTemplate pageTitle="">
        <PageTitle>Lista regulaminów</PageTitle>
        <DataTable data={data} />
      </PanelTemplate>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.token,
  };
};

export default connect(mapStateToProps, {
  deleteItem: deleteRegulationAction,
})(hasAccess(RegulationList, 'regulations-list'));
