import React, { Component } from "react";
import {
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBRow,
  MDBTabContent,
  MDBTabPane,
  MDBBtn
} from "mdbreact";
import { connect } from "react-redux";

class MicronutrientForm extends Component {
  state = {
    activeItem: "1"
  };

  componentDidMount() {
  }

  toggle = tab => {
    const { activeItem } = this.state;
    if (activeItem !== tab) {
      this.setState({
        activeItem: tab
      });
    }
  };

  render() {
    const { activeItem } = this.state;
    const { form, onSubmit, onChange } = this.props;
    const { namePL, nameEN,nameSPA } = form.data;
    const { errors } = form;


    const { namePL: namePL1, nameEN: nameEN1, nameSPA: nameSPA1 } = errors;

    return (
      <form onSubmit={onSubmit} id="form">
        <MDBNav tabs className="my-3 dark-purple">
          <MDBNavItem>
            <MDBNavLink
              active={activeItem === "1"}
              onClick={e => {
                e.preventDefault();
                this.toggle("1")
              }}
              role="tab"
              to="/tab1"
            >
              <MDBIcon icon="database"/> Dane
            </MDBNavLink>
          </MDBNavItem>
        </MDBNav>
        <MDBTabContent activeItem={activeItem}>
          <MDBTabPane tabId="1" role="tabpanel">
            <MDBRow>
              <MDBCol lg={6}>

                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="namePL"
                      name="namePL"
                      className={namePL1 === undefined ? "" : "is-invalid"}
                      value={namePL}
                      onChange={onChange}
                    >
                      {namePL1 !== undefined && (
                        <div className="invalid-feedback">{namePL1[0]}</div>
                      )}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>


                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="nameEN"
                      name="nameEN"
                      className={nameEN1 === undefined ? "" : "is-invalid"}
                      value={nameEN}
                      onChange={onChange}
                    >
                      {nameEN1 !== undefined && (
                        <div className="invalid-feedback">{nameEN1[0]}</div>
                      )}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>


                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="nameSPA"
                      name="nameSPA"
                      className={nameSPA1 === undefined ? "" : "is-invalid"}
                      value={nameSPA}
                      onChange={onChange}
                    >
                      {nameSPA1 !== undefined && (
                        <div className="invalid-feedback">{nameSPA1[0]}</div>
                      )}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </MDBTabPane>
        </MDBTabContent>

        <MDBBtn type="submit">
          Zapisz
        </MDBBtn>
      </form>
    );
  }
}

MicronutrientForm.defaultProps = {};

MicronutrientForm.propTypes = {};

const mapStateToProps = state => {
  return {
    token: state.token
  };
};

export default connect(
  mapStateToProps,
  {}
)(MicronutrientForm);
