import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { toast } from "mdbreact";
import ServiceForm from "./Form";
import { createAdminAction } from "../../actions";
import PanelTemplate from "../../templates/PanelTemplate";
import hasAccess from "../../middlewares/hasAccess";

class AdminAdminCreate extends Component {
  state = {
    redirect: null
  };

  handleSubmit = values => {
    const { createItem, token } = this.props;
    createItem(token, values).then(response => {
      if (response.id) {
        toast.success("Dodano administratora.");
        setTimeout(() => {
          this.setState({ redirect: `/administratorzy` });
        }, 2000);
      } else {
        toast.error("Nie udało się dodać nowego administratora. Sprawdź błędy.");
      }
    });
  };

  render() {
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect to={redirect}/>;
    }

    return (
      <PanelTemplate>
        <h3 className="py-2 color-dark-purple">Dodaj administratora</h3>
        <ServiceForm
          onSubmit={this.handleSubmit}
        />
      </PanelTemplate>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.token
  };
};

export default connect(
  mapStateToProps,
  {
    createItem: createAdminAction
  }
)(hasAccess(AdminAdminCreate,"admins-add"));
