import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { toast } from "mdbreact";
import { createMedicineAction } from "../../actions";
import PanelTemplate from "../../templates/PanelTemplate";
import hasAccess from "../../middlewares/hasAccess";
import MedicineForm from "./Form";

class MedicinesCreate extends Component {
  state = {
    form: {
      data: {
        special:false,
        titlePL:"",
        titleEN:"",
        order:1
      },
      errors: {}
    },
    redirect: null
  };

  handleChange = e => {
    const { form } = this.state;
    const { name, value } = e.target;
    const newForm = { ...form };

    newForm.data[name] = value;

    this.setState({ form: newForm });
  };


  handleChangeSpecial = value => {
    this.setState(state => {
      const { form } = state;
      const newForm = { ...form };
      newForm.data.special = value ? 1 : 0;
      return {
        form: newForm
      };
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { createItem, token } = this.props;
    const { form } = this.state;
    const { data } = form;

    createItem(token, data).then(response => {
      if (response.id) {
        toast.success("Dodano lek.");
        setTimeout(() => {
          this.setState({ redirect: `/leki` });
        }, 1000);
      } else {
        toast.error("Nie udało się dodać nowego leku. Sprawdź błędy.");
        const newForm = { ...form };
        newForm.errors = response;

        this.setState({ form: newForm });
      }
    });
  };

  render() {
    const { form, redirect } = this.state;
    if (redirect) {
      return <Redirect to={redirect}/>;
    }

    return (
      <PanelTemplate>
        <h3 className="py-2 color-dark-purple">Dodaj lek</h3>
        <MedicineForm
          form={form}
          onChange={this.handleChange}
          onChangeSpecial={this.handleChangeSpecial}
          onSubmit={this.handleSubmit}
        />
      </PanelTemplate>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.token
  };
};

export default connect(
  mapStateToProps,
  {
    createItem: createMedicineAction
  }
)(hasAccess(MedicinesCreate,"admins-list"));
