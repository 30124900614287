import React, { useEffect, useState } from 'react';
import { DataTable, PageTitle, NewResourceButton } from 'mdb-admin';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { MDBBtn, MDBIcon, MDBModal, MDBModalFooter, MDBModalHeader, toast } from 'mdbreact';
import PanelTemplate from '../../templates/PanelTemplate';
import { mainURL } from '../../actions';
import axiosClient from "../../api/axiosClient";

export const DietsList = () => {
  const { token } = useSelector(state => state);
  const [items, setItems] = useState([]);
  const [modalId, setModalId] = useState(null);
  const [change, setChange] = useState(1);
  useEffect(() => {
    axiosClient.get(`${mainURL}admin/diets`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data: { diets } }) => {
        setItems(diets);
      })
      .catch(error => {
        console.error(error);
      });
  }, [token, change]);
  return (
    <>
      <PanelTemplate pageTitle="">
        <PageTitle>Lista diet</PageTitle>
        <NewResourceButton path="/diety/dodaj">Dodaj</NewResourceButton>
        <DataTable
          data={{
            columns: [
              {
                label: 'ID',
                field: 'id',
                sort: 'asc',
              },
              {
                label: 'Imię',
                field: 'name',
                sort: 'asc',
              },
              {
                label: 'Nazwisko',
                field: 'lastname',
                sort: 'asc',
              },
              {
                label: 'Akcje',
                field: 'actions',
                sort: 'disabled',
              },
            ],
            rows: items.map(({ id, name, lastname }) => {
              return {
                id,
                name,
                lastname,
                actions: (
                  <>
                    <MDBBtn
                      title="Edytuj"
                      as="a"
                      href={`/diety/${id}`}
                      className="btn btn-default Ripple-parent"
                    >
                      Edytuj
                    </MDBBtn>
                    <MDBBtn
                      title="PDF"
                      onClick={() => {
                        //
                        axios
                          .get(`${mainURL}admin/diets/${id}/pdf`, {
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                          })
                          .then(({ data: { url } }) => {
                            window.open(url, '_blank');
                          })
                          .catch(error => {
                            console.error(error);
                          });
                      }}
                      className="btn btn-default Ripple-parent"
                    >
                      <MDBIcon icon="file-pdf" />
                    </MDBBtn>

                    <MDBBtn
                      title="Usuń"
                      pay
                      onClick={() => {
                        setModalId(id);
                      }}
                    >
                      <MDBIcon icon="trash-alt" />
                    </MDBBtn>
                    <MDBModal
                      isOpen={modalId === id}
                      toggle={() => {
                        setModalId(modalId === id ? null : id);
                      }}
                    >
                      <MDBModalHeader
                        toggle={() => {
                          setModalId(modalId === id ? null : id);
                        }}
                      >
                        Usunąć?
                      </MDBModalHeader>
                      <MDBModalFooter>
                        <MDBBtn
                          className="btn-default"
                          onClick={() => {
                            setModalId(modalId === id ? null : id);
                          }}
                        >
                          Anuluj
                        </MDBBtn>
                        <MDBBtn
                          className="btn-default"
                          onClick={() => {
                            axios
                              .delete(`${mainURL}admin/diets/${id}`, {
                                headers: {
                                  Authorization: `Bearer ${token}`,
                                },
                              })
                              .then(() => {
                                setChange(change + 1);
                                toast.success('Usunięto dietę');
                              })
                              .catch(() => {
                                toast.error('Błąd - spróbuj ponownie');
                              });
                          }}
                        >
                          Usuń
                        </MDBBtn>
                      </MDBModalFooter>
                    </MDBModal>
                  </>
                ),
              };
            }),
          }}
        />
      </PanelTemplate>
    </>
  );
};
