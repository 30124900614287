import React, { Component } from 'react';
import {
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBRow,
  MDBTabContent,
  MDBTabPane,
  MDBBtn,
  MDBSelect,
  MDBBtnFixed,
} from 'mdbreact';
import { connect } from 'react-redux';
import axios from 'axios';
import { mainURL } from '../../actions';

class ProductForm extends Component {
  state = {
    activeItem: '1',
    items: [],
  };

  componentDidMount() {
    const { token } = this.props;
    axios
      .get(`${mainURL}admin/micronutrients`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        const { micronutrients } = response.data;

        this.setState({
          items: micronutrients,
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  toggle = tab => {
    const { activeItem } = this.state;
    if (activeItem !== tab) {
      this.setState({
        activeItem: tab,
      });
    }
  };

  render() {
    const { activeItem, items } = this.state;
    const {
      form,
      onSubmit,
      onChange,
      onChangeName,
      onAddMicronutrient,
      onRemoveMicronutrient,
    } = this.props;
    const {
      namePL,
      nameEN,
      nameSPA,
      kcal,
      micronutrient_product: micronutrientProduct,
    } = form.data;
    const { errors } = form;

    const { namePL: namePL1, nameEN: nameEN1, nameSPA: nameSPA1, kcal: kcal1 } = errors;

    return (
      <form onSubmit={onSubmit} id="form">
        <MDBNav tabs className="my-3 dark-purple">
          <MDBNavItem>
            <MDBNavLink
              active={activeItem === '1'}
              onClick={e => {
                e.preventDefault();
                this.toggle('1');
              }}
              role="tab"
              to="/tab1"
            >
              <MDBIcon icon="database" /> Dane
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBNavLink
              active={activeItem === '2'}
              onClick={e => {
                e.preventDefault();
                this.toggle('2');
              }}
              role="tab"
              to="/tab1"
            >
              <MDBIcon icon="database" /> Mikroskładniki
            </MDBNavLink>
          </MDBNavItem>
        </MDBNav>
        <MDBTabContent activeItem={activeItem}>
          <MDBTabPane tabId="1" role="tabpanel">
            <MDBRow>
              <MDBCol lg={6}>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Nazwa PL"
                      name="namePL"
                      className={namePL1 === undefined ? '' : 'is-invalid'}
                      value={namePL}
                      onChange={onChange}
                    >
                      {namePL1 !== undefined && (
                        <div className="invalid-feedback">{namePL1[0]}</div>
                      )}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Nazwa ENG"
                      name="nameEN"
                      className={nameEN1 === undefined ? '' : 'is-invalid'}
                      value={nameEN}
                      onChange={onChange}
                    >
                      {nameEN1 !== undefined && (
                        <div className="invalid-feedback">{nameEN1[0]}</div>
                      )}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Nazwa SPA"
                      name="nameSPA"
                      className={nameSPA1 === undefined ? '' : 'is-invalid'}
                      value={nameSPA}
                      onChange={onChange}
                    >
                      {nameSPA1 !== undefined && (
                        <div className="invalid-feedback">{nameSPA1[0]}</div>
                      )}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Kcal/100g"
                      name="kcal"
                      className={kcal1 === undefined ? '' : 'is-invalid'}
                      value={kcal}
                      onChange={onChange}
                      type="number"
                    >
                      {kcal1 !== undefined && <div className="invalid-feedback">{kcal1[0]}</div>}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>
                {/*
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="unitOfMeasurePL"
                      name="unitOfMeasurePL"
                      className={unitOfMeasurePL1 === undefined ? '' : 'is-invalid'}
                      value={unitOfMeasurePL}
                      onChange={onChange}
                    >
                      {unitOfMeasurePL1 !== undefined && (
                        <div className="invalid-feedback">{unitOfMeasurePL1[0]}</div>
                      )}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="unitOfMeasureEN"
                      name="unitOfMeasureEN"
                      className={unitOfMeasureEN1 === undefined ? '' : 'is-invalid'}
                      value={unitOfMeasureEN}
                      onChange={onChange}
                    >
                      {unitOfMeasureEN1 !== undefined && (
                        <div className="invalid-feedback">{unitOfMeasureEN1[0]}</div>
                      )}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="unitOfMeasureSPA"
                      name="unitOfMeasureSPA"
                      className={unitOfMeasureSPA1 === undefined ? '' : 'is-invalid'}
                      value={unitOfMeasureSPA}
                      onChange={onChange}
                    >
                      {unitOfMeasureSPA1 !== undefined && (
                        <div className="invalid-feedback">{unitOfMeasureSPA1[0]}</div>
                      )}
                    </MDBInput>
                  </MDBCol>
                </MDBRow> */}
              </MDBCol>
            </MDBRow>
          </MDBTabPane>
          <MDBTabPane tabId="2" role="tabpanel">
            <MDBRow>
              <MDBCol lg={6}>
                {micronutrientProduct.map((item, index) => {
                  const { value, micronutrient_id: micronutrientId } = item;
                  return (
                    <MDBRow>
                      <MDBCol>
                        <MDBInput
                          label="wartość"
                          name={`micronutrient_product__${index}__value`}
                          value={value}
                          onChange={onChange}
                          type="number"
                          step="0.01"
                          className={
                            errors[`micronutrient_product.${index}.value`] === undefined
                              ? ''
                              : 'is-invalid'
                          }
                        >
                          {errors[`micronutrient_product.${index}.value`] !== undefined && (
                            <div className="invalid-feedback">
                              {errors[`micronutrient_product.${index}.value`][0]}
                            </div>
                          )}
                        </MDBInput>
                      </MDBCol>
                      <MDBCol>
                        <MDBSelect
                          options={items.map(micronutrient => {
                            return {
                              text: micronutrient.namePL,
                              value: micronutrient.id,
                              checked: micronutrient.id === micronutrientId,
                            };
                          })}
                          selected="Wybierz mikroskładnik"
                          label="Wybierz mikroskładnik"
                          getValue={selectValue => {
                            if (micronutrientId !== selectValue[0]) {
                              if (selectValue[0]) {
                                onChangeName(
                                  selectValue[0],
                                  `micronutrient_product__${index}__micronutrient_id`,
                                );
                              }
                            }
                          }}
                        />
                        {errors[`micronutrient_product.${index}.micronutrient_id`] !==
                          undefined && (
                          <div className="invalid-feedback d-flex">
                            {errors[`micronutrient_product.${index}.micronutrient_id`][0]}
                          </div>
                        )}
                      </MDBCol>
                      <MDBCol>
                        <MDBInput
                          label="wartość"
                          name={`micronutrient_product__${index}__kcal`}
                          value={
                            items.find(micronutrient => micronutrient.id === micronutrientId)
                              ? items.find(micronutrient => micronutrient.id === micronutrientId)
                                  .multiplier * value
                              : 0
                          }
                          type="number"
                          disabled
                        />
                      </MDBCol>

                      <MDBCol>
                        <MDBBtn
                          onClick={() => {
                            onRemoveMicronutrient(index);
                          }}
                          size="sm"
                          floating
                          color="red"
                        >
                          <MDBIcon icon="times" />
                        </MDBBtn>
                      </MDBCol>
                    </MDBRow>
                  );
                })}

                <span>
                  Suma kcal:{' '}
                  {!!micronutrientProduct.length &&
                    micronutrientProduct
                      .map(item => {
                        const { value, micronutrient_id: micronutrientId } = item;
                        const toAdd = items.find(
                          micronutrient => micronutrient.id === micronutrientId,
                        )
                          ? items.find(micronutrient => micronutrient.id === micronutrientId)
                              .multiplier * value
                          : 0;
                        return toAdd;
                      })
                      .reduce((previousValue, item) => {
                        return parseFloat(item) + parseFloat(previousValue);
                      })}
                </span>
              </MDBCol>
            </MDBRow>

            <MDBBtnFixed
              floating
              size="lg"
              icon="plus"
              gradient="blue"
              style={{ bottom: '45px', right: '24px' }}
              onClick={() => {
                onAddMicronutrient();
              }}
            />
          </MDBTabPane>
        </MDBTabContent>

        <MDBBtn type="submit">Zapisz</MDBBtn>
      </form>
    );
  }
}

ProductForm.defaultProps = {};

ProductForm.propTypes = {};

const mapStateToProps = state => {
  return {
    token: state.token,
  };
};

export default connect(mapStateToProps, {})(ProductForm);
