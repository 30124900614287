import React, { useEffect, useState } from 'react';
import {
  MDBBtn,
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBRow,
  MDBTabContent,
  MDBTabPane,
  toast,
} from 'mdbreact';
import { Field, Form } from 'react-final-form';
import axios from 'axios';
import { useSelector } from 'react-redux';
import PanelTemplate from '../../templates/PanelTemplate';
import { mainURL } from '../../actions';
import { Settlement } from './Settlement';
import { Codes } from './Codes';

export const PartnerEdit = ({
  match: {
    params: { id },
  },
}) => {
  const [activeItem, setActiveItem] = useState('1');
  const token = useSelector(state => state.token);
  const [partner, setPartner] = useState({});

  useEffect(() => {
    axios
      .get(`${mainURL}admin/partners/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data: { partner: newPartner } }) => {
        setPartner(newPartner);
      });
  }, [id, token]);
  return (
    <PanelTemplate>
      <h3 className="py-2 color-dark-purple">Edytuj partnera</h3>
      <MDBNav tabs className="my-3 dark-purple">
        <MDBNavItem>
          <MDBNavLink
            active={activeItem === '1'}
            onClick={e => {
              e.preventDefault();
              setActiveItem('1');
            }}
            role="tab"
            to="/tab1"
          >
            <MDBIcon icon="database" /> Dane
          </MDBNavLink>
        </MDBNavItem>
        <MDBNavItem>
          <MDBNavLink
            active={activeItem === '2'}
            onClick={e => {
              e.preventDefault();
              setActiveItem('2');
            }}
            role="tab"
            to="/tab2"
          >
            <MDBIcon icon="database" /> Kody rabatowe
          </MDBNavLink>
        </MDBNavItem>

        <MDBNavItem>
          <MDBNavLink
            active={activeItem === '3'}
            onClick={e => {
              e.preventDefault();
              setActiveItem('3');
            }}
            role="tab"
            to="/tab2"
          >
            <MDBIcon icon="database" /> Rozliczenia
          </MDBNavLink>
        </MDBNavItem>
      </MDBNav>
      <MDBTabContent activeItem={activeItem}>
        <MDBTabPane tabId="1" role="tabpanel">
          <MDBRow>
            <MDBCol lg={6}>
              <Form
                onSubmit={values => {
                  axios
                    .put(`${mainURL}admin/partners/${id}`, values, {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    })
                    .then(() => {
                      toast.success('Zaktualizowano.');
                    });
                }}
                initialValues={partner}
                render={({ handleSubmit, submitting }) => (
                  <form onSubmit={handleSubmit}>
                    <Field
                      name="name"
                      render={({ input, meta }) => (
                        <MDBInput
                          label="Imię"
                          className={meta.touched && meta.error ? 'is-invalid' : ''}
                          {...input}
                        >
                          {meta.touched && meta.error && (
                            <div className="invalid-feedback">{meta.error}</div>
                          )}
                        </MDBInput>
                      )}
                    />
                    <Field
                      name="surname"
                      render={({ input, meta }) => (
                        <MDBInput
                          label="Nazwisko"
                          className={meta.touched && meta.error ? 'is-invalid' : ''}
                          {...input}
                        >
                          {meta.touched && meta.error && (
                            <div className="invalid-feedback">{meta.error}</div>
                          )}
                        </MDBInput>
                      )}
                    />
                    <Field
                      name="organization"
                      render={({ input, meta }) => (
                        <MDBInput
                          label="Organizacja"
                          className={meta.touched && meta.error ? 'is-invalid' : ''}
                          {...input}
                        >
                          {meta.touched && meta.error && (
                            <div className="invalid-feedback">{meta.error}</div>
                          )}
                        </MDBInput>
                      )}
                    />
                    <Field
                      name="phone"
                      render={({ input, meta }) => (
                        <MDBInput
                          label="Telefon"
                          className={meta.touched && meta.error ? 'is-invalid' : ''}
                          {...input}
                        >
                          {meta.touched && meta.error && (
                            <div className="invalid-feedback">{meta.error}</div>
                          )}
                        </MDBInput>
                      )}
                    />

                    <MDBBtn type="submit" disabled={submitting}>
                      Zapisz
                    </MDBBtn>
                  </form>
                )}
              />
            </MDBCol>
          </MDBRow>
        </MDBTabPane>
        <MDBTabPane tabId="2" role="tabpanel">
          <Codes id={id} />
        </MDBTabPane>
        <MDBTabPane tabId="3" role="tabpanel">
          <Settlement id={id} />
        </MDBTabPane>
      </MDBTabContent>
    </PanelTemplate>
  );
};
