import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

export default function(ComposedComponent) {
  class Authentication extends Component {
    PropTypes = {
      router: PropTypes.object,
    };

    componentWillMount() {
      const { authenticated, history } = this.props;
      if (!authenticated) {
        history.push('/login');
      }
    }

    componentWillUpdate(nextProps) {
      const { history } = this.props;
      if (!nextProps.authenticated) {
        history.push('/login');
      }
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }
  function mapStateToProps(state) {
    return { authenticated: !!state.token };
  }
  return connect(mapStateToProps)(Authentication);
}
