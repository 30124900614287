import React, { Component } from 'react';
import {
  MDBBtn,
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBRow,
  MDBTabContent,
  MDBTabPane,
} from 'mdbreact';
import { connect } from 'react-redux';
import axios from 'axios';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { mainURL } from '../../actions';

class AdminForm extends Component {
  state = {
    activeItem: '1',
    permissions: [],
  };

  componentDidMount() {
    const {
      props: { token },
    } = this;
    axios
      .get(`${mainURL}admin/permissions`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        const { permissions: newPermissions } = response.data;
        this.setState({
          permissions: newPermissions,
        });
      })
      .catch(() => {});
  }

  toggle = tab => {
    const { activeItem } = this.state;
    if (activeItem !== tab) {
      this.setState({
        activeItem: tab,
      });
    }
  };

  render() {
    const { activeItem, permissions } = this.state;
    const { onSubmit, initialValues, isEditing } = this.props;

    // const { password: password1, email: email1, name: name1, password_confirmation: passwordConfirmation1 } = errors;
    return (
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        mutators={{
          // potentially other mutators could be merged here
          ...arrayMutators,
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} id="form">
            <MDBNav tabs className="my-3 dark-purple">
              <MDBNavItem>
                <MDBNavLink
                  active={activeItem === '1'}
                  onClick={e => {
                    e.preventDefault();
                    this.toggle('1');
                  }}
                  role="tab"
                  to="/tab1"
                >
                  <MDBIcon icon="database" /> Dane
                </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink
                  active={activeItem === '2'}
                  onClick={e => {
                    e.preventDefault();
                    this.toggle('2');
                  }}
                  role="tab"
                  to="/tab2"
                >
                  <MDBIcon icon="database" /> Uprawnienia
                </MDBNavLink>
              </MDBNavItem>
            </MDBNav>
            <MDBTabContent activeItem={activeItem}>
              <MDBTabPane tabId="1" role="tabpanel">
                <MDBRow>
                  <MDBCol lg={6}>
                    <MDBRow>
                      <MDBCol>
                        <Field
                          name="name"
                          type="text"
                          label="Nazwa"
                          render={({ input, meta, ...rest }) => (
                            <MDBInput
                              className={meta.touched && meta.error ? 'is-invalid' : ''}
                              {...input}
                              {...rest}
                            >
                              {meta.touched && meta.error && (
                                <div className="invalid-feedback">{meta.error}</div>
                              )}
                            </MDBInput>
                          )}
                        />
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol>
                        <Field
                          name="email"
                          type="email"
                          label="Email"
                          render={({ input, meta, ...rest }) => (
                            <MDBInput
                              className={meta.touched && meta.error ? 'is-invalid' : ''}
                              {...input}
                              {...rest}
                            >
                              {meta.touched && meta.error && (
                                <div className="invalid-feedback">{meta.error}</div>
                              )}
                            </MDBInput>
                          )}
                        />
                      </MDBCol>
                    </MDBRow>

                    {!isEditing && (
                      <>
                        <MDBRow>
                          <MDBCol>
                            <Field
                              name="password"
                              type="password"
                              label="Hasło"
                              render={({ input, meta, ...rest }) => (
                                <MDBInput
                                  className={meta.touched && meta.error ? 'is-invalid' : ''}
                                  {...input}
                                  {...rest}
                                >
                                  {meta.touched && meta.error && (
                                    <div className="invalid-feedback">{meta.error}</div>
                                  )}
                                </MDBInput>
                              )}
                            />
                          </MDBCol>
                        </MDBRow>

                        <MDBRow>
                          <MDBCol>
                            <Field
                              name="password_confirmation"
                              type="password"
                              label="Powtórz hasło"
                              render={({ input, meta, ...rest }) => (
                                <MDBInput
                                  className={meta.touched && meta.error ? 'is-invalid' : ''}
                                  {...input}
                                  {...rest}
                                >
                                  {meta.touched && meta.error && (
                                    <div className="invalid-feedback">{meta.error}</div>
                                  )}
                                </MDBInput>
                              )}
                            />
                          </MDBCol>
                        </MDBRow>
                      </>
                    )}
                  </MDBCol>
                </MDBRow>
              </MDBTabPane>
            </MDBTabContent>
            <MDBTabContent activeItem={activeItem}>
              <MDBTabPane tabId="2" role="tabpanel">
                <MDBRow>
                  {permissions.map(({ details, name_show: showName }) => (
                    <MDBCol lg={3} className="mb-3">
                      <MDBRow>
                        <MDBCol>
                          {details.map(({ id, name_show: name }) => (
                            <Field
                              name="permissions[]"
                              type="checkbox"
                              value={id}
                              id={`${id}-checkbox`}
                              label={`${showName} ${name}`}
                              render={({ input, meta, ...rest }) => (
                                <MDBInput {...input} {...rest} />
                              )}
                            />
                          ))}
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  ))}
                </MDBRow>
              </MDBTabPane>
            </MDBTabContent>

            <MDBBtn type="submit">Zapisz</MDBBtn>
          </form>
        )}
      />
    );
  }
}

AdminForm.defaultProps = {
  initialValues: {},
};

AdminForm.propTypes = {};

const mapStateToProps = state => {
  return {
    token: state.token,
  };
};

export default connect(mapStateToProps, {})(AdminForm);
