import React, { Component } from 'react';
import styled from 'styled-components';
import { MDBBtn, MDBFileInput, MDBIcon } from 'mdbreact';
import axios from 'axios';
import { connect } from 'react-redux';
import { mainURL } from '../actions';

const StyledContainer = styled('div')`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  grid-auto-rows: auto;
  cursor: pointer;
`;

const Item = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledImg = styled('img')`
  max-width: 100%;
  max-height: 100%;
  min-height: 60px;
`;

const StyledMDBBtn = styled(MDBBtn)`
  text-align: center;
  font-size: 10px;
`;

const LoadingWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`;

class Manage extends Component {
  state = {
    loading: false,
    files: [],
    extImages: ['png', 'bmp', 'jpg', 'jpeg'],
  };

  componentDidMount(): void {
    this.getFiles();
  }

  selectFile = file => {
    const CKEditorFuncNum = 0;
    window.parent.opener.CKEDITOR.tools.callFunction(CKEditorFuncNum, file, '');
    window.close();
  };

  getFiles = () => {
    this.setState({
      loading: true,
    });

    const { token } = this.props;

    axios
      .get(`${mainURL}files`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(response => {
        const { files } = response.data;
        this.setState({
          loading: false,
          files,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  handleSubmit = file => {
    const { token } = this.props;
    const http = axios.create({
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });
    const formData = new FormData();
    formData.append('file', file[0]);

    http.post(`${mainURL}files`, formData).then(() => {
      this.getFiles();
    });
  };

  isImage = ext => {
    const { extImages } = this.state;
    return extImages.indexOf(ext) !== -1;
  };

  getIcon = ext => {
    const size = '5x';
    switch (ext) {
      case 'xls':
        return <MDBIcon size={size} icon="file-excel" />;
      case 'xlsx':
        return <MDBIcon size={size} icon="file-excel" />;
      case 'csv':
        return <MDBIcon size={size} icon="file-csv" />;
      case 'doc':
        return <MDBIcon size={size} icon="file-word" />;
      case 'docx':
        return <MDBIcon size={size} icon="file-word" />;
      case 'zip':
        return <MDBIcon size={size} icon="file-archive" />;
      case 'rar':
        return <MDBIcon size={size} icon="file-archive" />;
      case 'pdf':
        return <MDBIcon size={size} icon="file-pdf" />;
      case 'ppt':
        return <MDBIcon size={size} icon="file-powerpoint" />;
      case 'pptx':
        return <MDBIcon size={size} icon="file-powerpoint" />;
      default:
        return <MDBIcon size={size} icon="file" />;
    }
  };

  render() {
    const { loading, files } = this.state;
    if (loading) {
      return (
        <LoadingWrapper>
          <div className="spinner-grow text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </LoadingWrapper>
      );
    }
    return (
      <div>
        <MDBFileInput
          reverse
          getValue={this.handleSubmit}
          multiple
          btnTitle="Wybierz plik"
          textFieldTitle="Wybierz plik"
          btnColor="blue"
          btn-size="sm"
        />
        <StyledContainer>
          {files.map(file => {
            return (
              <Item
                onClick={() => {
                  this.selectFile(file);
                }}
              >
                {this.isImage(file.split('.')[file.split('.').length - 1]) ? (
                  <StyledImg src={file} />
                ) : (
                  <>
                    {this.getIcon(file.split('.')[file.split('.').length - 1])}
                    {file.split('/')[file.split('.').length - 1]}
                  </>
                )}
                <StyledMDBBtn gradient="blue" outline>
                  Wybierz
                </StyledMDBBtn>
              </Item>
            );
          })}
        </StyledContainer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.token,
  };
};

export default connect(
  mapStateToProps,
  {},
)(Manage);

// export default Manage;
