import React, { useEffect, useState } from 'react';
import { DataTable, NewResourceButton, PageTitle } from 'mdb-admin';
import { MDBBtn, MDBIcon, MDBModal, MDBModalFooter, MDBModalHeader } from 'mdbreact';
import axios from 'axios';
import { useSelector } from 'react-redux';
import PanelTemplate from '../../templates/PanelTemplate';
import { mainURL } from '../../actions';
import axiosClient from "../../api/axiosClient";

export const CodesList = () => {
  const [codes, setCodes] = useState([]);
  const [change, setChange] = useState(0);
  const [modal, setModal] = useState(0);
  const token = useSelector(state => state.token);

  useEffect(() => {
    axiosClient
      .get(`${mainURL}admin/codes`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        if(data){
          setCodes(data?.codes);
        }
      });
  }, [token, change]);

  const toggle = id => {
    setModal(id === modal ? 0 : id);
  };
  const toggleCode = id => {
    axios
      .post(
        `${mainURL}admin/codes/${id}/toggle`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(() => {
        setModal(0);
        setChange(change + 1);
      });
  };

  return (
    <PanelTemplate pageTitle="">
      <PageTitle>Lista kodów promocyjnych</PageTitle>
      <NewResourceButton path="/kody/dodaj">Dodaj</NewResourceButton>
      <DataTable
        data={{
          columns: [
            {
              label: 'Kod',
              field: 'code',
              sort: 'asc',
            },
            {
              label: 'Partner',
              field: 'partnerName',
              sort: 'asc',
            },
            {
              label: 'Aktywny',
              field: 'active',
              sort: 'asc',
            },
            {
              label: 'Do użycia',
              field: 'canBeUsed',
              sort: 'asc',
            },
            {
              label: 'Akcje',
              field: 'actions',
              sort: 'disabled',
            },
          ],
          rows: codes.map(({ id, code, partnerName, active, canBeUsed }) => {
            return {
              code,
              partnerName,
              canBeUsed: <>{canBeUsed ? 'TAK' : 'NIE'}</>,
              active: <>{active ? 'TAK' : 'NIE'}</>,
              actions: (
                <>
                  <MDBBtn
                    title="Usuń"
                    pay
                    onClick={() => {
                      toggle(id);
                    }}
                  >
                    <MDBIcon icon={!active ? 'check-circle' : 'times-circle'} />
                  </MDBBtn>
                  <MDBModal
                    isOpen={modal === id}
                    toggle={() => {
                      toggle(id);
                    }}
                  >
                    <MDBModalHeader
                      toggle={() => {
                        toggle(id);
                      }}
                    >
                      {active ? 'Deaktywować' : 'Aktywować'}?
                    </MDBModalHeader>
                    <MDBModalFooter>
                      <MDBBtn
                        className="btn-default"
                        onClick={() => {
                          toggle(id);
                        }}
                      >
                        Anuluj
                      </MDBBtn>
                      <MDBBtn
                        className="btn-default"
                        onClick={() => {
                          toggleCode(id);
                        }}
                      >
                        {active ? 'Deaktywuj' : 'Aktywuj'}
                      </MDBBtn>
                    </MDBModalFooter>
                  </MDBModal>
                </>
              ),
            };
          }),
        }}
      />
    </PanelTemplate>
  );
};
