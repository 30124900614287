export const theme = {
  red: '#C7161E',
  yellow: '#ffff00',
  black: '#000000',
  white: '#ffffff',
  darkgray: '#888',
  lightgray: '#DDDDDD',
  lightergray: '#EEEEEE',
  font: {
    size: {
      xxxs: '.8em',
      xxs: '.9em',
      xs: '1em',
      s: '1.2em',
      m: '1.4em',
      l: '2em',
      xl: '4em',
      xxl: '8em',
    },
    weight: {
      light: '300',
      regular: '400',
      bold: '700',
    },
  },
};
