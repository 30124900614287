import React, { useEffect, useState } from 'react';
import { DataTable, NewResourceButton, PageTitle } from 'mdb-admin';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { MDBBtn, MDBIcon, MDBModal, MDBModalFooter, MDBModalHeader } from 'mdbreact';
import PanelTemplate from '../../templates/PanelTemplate';
import { mainURL } from '../../actions';
import axiosClient from "../../api/axiosClient";

export const PartnersList = () => {
  const [partners, setPartners] = useState([]);
  const [change, setChange] = useState(0);
  const [modal, setModal] = useState(0);
  const token = useSelector(state => state.token);

  useEffect(() => {
    axiosClient
      .get(`${mainURL}admin/partners`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        setPartners(data.partners);
      });
  }, [token, change]);

  const toggle = id => {
    setModal(id === modal ? 0 : id);
  };
  const deleteItem = id => {
    axios
      .delete(`${mainURL}admin/partners/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setModal(0);
        setChange(change + 1);
      });
  };

  return (
    <PanelTemplate pageTitle="">
      <PageTitle>Lista partnerów</PageTitle>
      <NewResourceButton path="/partnerzy/dodaj">Dodaj</NewResourceButton>
      <DataTable
        data={{
          columns: [
            {
              label: 'ID',
              field: 'id',
              sort: 'asc',
            },
            {
              label: 'Imię',
              field: 'name',
              sort: 'asc',
            },
            {
              label: 'Nazwisko',
              field: 'surname',
              sort: 'asc',
            },
            {
              label: 'Akcje',
              field: 'actions',
              sort: 'disabled',
            },
          ],
          rows: partners.map(({ id, name, surname }) => {
            return {
              id,
              name,
              surname,
              actions: (
                <>
                  <MDBBtn
                    title="Edytuj"
                    as="a"
                    href={`/partnerzy/${id}`}
                    className="btn btn-default Ripple-parent"
                  >
                    Edytuj
                  </MDBBtn>

                  <MDBBtn
                    title="Usuń"
                    pay
                    onClick={() => {
                      toggle(id);
                    }}
                  >
                    <MDBIcon icon="trash-alt" />
                  </MDBBtn>
                  <MDBModal
                    isOpen={modal === id}
                    toggle={() => {
                      toggle(id);
                    }}
                  >
                    <MDBModalHeader
                      toggle={() => {
                        toggle(id);
                      }}
                    >
                      Usunąć?
                    </MDBModalHeader>
                    <MDBModalFooter>
                      <MDBBtn
                        className="btn-default"
                        onClick={() => {
                          toggle(id);
                        }}
                      >
                        Anuluj
                      </MDBBtn>
                      <MDBBtn
                        className="btn-default"
                        onClick={() => {
                          deleteItem(id);
                        }}
                      >
                        Usuń
                      </MDBBtn>
                    </MDBModalFooter>
                  </MDBModal>
                </>
              ),
            };
          }),
        }}
      />
    </PanelTemplate>
  );
};
