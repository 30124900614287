import React, { Component } from 'react';
import {
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBRow,
  MDBTabContent,
  MDBTabPane,
  MDBBtn,
  MDBSelect,
  MDBBtnFixed,
  MDBFileInput,
} from 'mdbreact';
import { connect } from 'react-redux';
import axios from 'axios';
import styled from 'styled-components';
import { mainURL } from '../../actions';

const StyledMDBCol = styled(MDBCol)`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 10px;
`;

class ProductForm extends Component {
  state = {
    activeItem: '1',
    items: [],
    micronutrients: [],
  };

  componentDidMount() {
    const { token } = this.props;
    axios
      .get(`${mainURL}admin/productsWithRelations`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        const { products } = response.data;

        this.setState({
          items: products,
        });
      })
      .catch(() => {
        // console.error(error);
      });

    axios
      .get(`${mainURL}admin/micronutrients`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        const { micronutrients } = response.data;

        this.setState({
          micronutrients,
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  toggle = tab => {
    const { activeItem } = this.state;
    if (activeItem !== tab) {
      this.setState({
        activeItem: tab,
      });
    }
  };

  render() {
    const { activeItem, items, micronutrients } = this.state;
    const { form, onSubmit, onChange, onChangeName, onAddProduct, onRemoveProduct } = this.props;
    const {
      namePL,
      nameEN,
      nameSPA,
      descriptionPL,
      descriptionEN,
      descriptionSPA,
      recipe_products: recipeProducts,
      url,
      meals,
    } = form.data;
    const { errors } = form;

    const {
      namePL: namePL1,
      nameEN: nameEN1,
      nameSPA: nameSPA1,
      descriptionPL: descriptionPL1,
      descriptionEN: descriptionEN1,
      descriptionSPA: descriptionSPA1,
      photo: photo1,
    } = errors;

    return (
      <form onSubmit={onSubmit} id="form">
        <MDBNav tabs className="my-3 dark-purple">
          <MDBNavItem>
            <MDBNavLink
              active={activeItem === '1'}
              onClick={e => {
                e.preventDefault();
                this.toggle('1');
              }}
              role="tab"
              to="/tab1"
            >
              <MDBIcon icon="database" /> Dane
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBNavLink
              active={activeItem === '2'}
              onClick={e => {
                e.preventDefault();
                this.toggle('2');
              }}
              role="tab"
              to="/tab2"
            >
              <MDBIcon icon="database" /> Produkty
            </MDBNavLink>
          </MDBNavItem>
        </MDBNav>
        <MDBTabContent activeItem={activeItem}>
          <MDBTabPane tabId="1" role="tabpanel">
            <MDBRow>
              <MDBCol>
                <MDBInput
                  label="Nazwa PL"
                  name="namePL"
                  className={namePL1 === undefined ? '' : 'is-invalid'}
                  value={namePL}
                  onChange={onChange}
                >
                  {namePL1 !== undefined && <div className="invalid-feedback">{namePL1[0]}</div>}
                </MDBInput>
              </MDBCol>

              <MDBCol>
                <MDBInput
                  label="Nazwa ENG"
                  name="nameEN"
                  className={nameEN1 === undefined ? '' : 'is-invalid'}
                  value={nameEN}
                  onChange={onChange}
                >
                  {nameEN1 !== undefined && <div className="invalid-feedback">{nameEN1[0]}</div>}
                </MDBInput>
              </MDBCol>

              <MDBCol>
                <MDBInput
                  label="Nazwa SPA"
                  name="nameSPA"
                  className={nameSPA1 === undefined ? '' : 'is-invalid'}
                  value={nameSPA}
                  onChange={onChange}
                >
                  {nameSPA1 !== undefined && <div className="invalid-feedback">{nameSPA1[0]}</div>}
                </MDBInput>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <MDBInput
                  label="Opis PL"
                  name="descriptionPL"
                  className={descriptionPL1 === undefined ? '' : 'is-invalid'}
                  value={descriptionPL}
                  onChange={onChange}
                >
                  {descriptionPL1 !== undefined && (
                    <div className="invalid-feedback">{descriptionPL1[0]}</div>
                  )}
                </MDBInput>
              </MDBCol>

              <MDBCol>
                <MDBInput
                  label="Opis ENG"
                  name="descriptionEN"
                  className={descriptionEN1 === undefined ? '' : 'is-invalid'}
                  value={descriptionEN}
                  onChange={onChange}
                >
                  {descriptionEN1 !== undefined && (
                    <div className="invalid-feedback">{descriptionEN1[0]}</div>
                  )}
                </MDBInput>
              </MDBCol>
              <MDBCol>
                <MDBInput
                  label="Opis SPA"
                  name="descriptionSPA"
                  className={descriptionSPA1 === undefined ? '' : 'is-invalid'}
                  value={descriptionSPA}
                  onChange={onChange}
                >
                  {descriptionSPA1 !== undefined && (
                    <div className="invalid-feedback">{descriptionSPA1[0]}</div>
                  )}
                </MDBInput>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol lg={6}>
                <MDBSelect
                  options={[1, 2, 3, 4, 5].map(item => {
                    return {
                      text: `Posiłek ${item}`,
                      value: item,
                      checked: meals.filter(meal => {
                        return meal.value === item;
                      }).length,
                    };
                  })}
                  selected="Wybierz posiłki"
                  label="Wybierz posiłki"
                  multiple
                  getValue={selectValue => {
                    if (selectValue) {
                      if (meals.length !== selectValue.length) {
                        onChangeName(
                          selectValue.map(meal => {
                            return {
                              value: meal,
                            };
                          }),
                          `meals`,
                        );
                      }
                    }
                  }}
                />
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol lg={6}>
                <MDBFileInput
                  textFieldTitle="Wybierz zdjęcie"
                  btnTitle="Wybierz"
                  getValue={value => {
                    onChangeName(value[0], 'photo');
                  }}
                />

                {photo1 !== undefined && <div className="invalid-feedback d-flex">{photo1[0]}</div>}
              </MDBCol>
              <MDBCol lg={6}>{url && <img src={url} alt="" className="w-100" />}</MDBCol>
            </MDBRow>
          </MDBTabPane>
          <MDBTabPane tabId="2" role="tabpanel">
            {recipeProducts.map((item, index) => {
              const { value, product_id: productId } = item;
              const product = items.find(({ id: ProdId }) => {
                return ProdId === productId;
              }) || {
                kcal: 0,
                micronutrients: [],
              };
              return (
                <MDBRow className="border-bottom w-100">
                  <MDBCol size={2}>
                    <MDBSelect
                      options={items.map(selectItem => {
                        return {
                          text: selectItem.namePL,
                          value: selectItem.id,
                          checked: selectItem.id === productId,
                        };
                      })}
                      search
                      selected="Wybierz produkt"
                      label="Wybierz produkt"
                      getValue={selectValue => {
                        if (productId !== selectValue[0]) {
                          if (selectValue[0]) {
                            onChangeName(selectValue[0], `recipe_products__${index}__product_id`);
                          }
                        }
                      }}
                    />
                    {errors[`recipe_products.${index}.product_id`] !== undefined && (
                      <div className="invalid-feedback d-flex">
                        {errors[`recipe_products.${index}.product_id`][0]}
                      </div>
                    )}
                  </MDBCol>
                  <StyledMDBCol>
                    <MDBInput
                      label="Gramy"
                      name={`recipe_products__${index}__value`}
                      value={value}
                      onChange={onChange}
                      type="number"
                      step="0.01"
                      min={0}
                      className={
                        errors[`recipe_products.${index}.value`] === undefined ? '' : 'is-invalid'
                      }
                    >
                      {errors[`recipe_products.${index}.value`] !== undefined && (
                        <div className="invalid-feedback">
                          {errors[`recipe_products.${index}.value`][0]}
                        </div>
                      )}
                    </MDBInput>
                    {micronutrients.map(micronutrient => {
                      const micronutrientsIndex = product.micronutrients.findIndex(elem => {
                        return elem.id === micronutrient.id;
                      });
                      return (
                        <MDBInput
                          label={micronutrient.namePL}
                          value={(
                            (micronutrientsIndex > -1
                              ? product.micronutrients[micronutrientsIndex].value *
                                product.micronutrients[micronutrientsIndex].multiplier
                              : 0) *
                            (value / 100)
                          ).toFixed(2)}
                          disabled={1}
                        />
                      );
                    })}

                    <MDBInput
                      label="kcal"
                      value={((product ? product.kcal : 0) * (value / 100)).toFixed(2)}
                      disabled={1}
                    />
                  </StyledMDBCol>
                  <MDBCol size={1}>
                    <MDBBtn
                      onClick={() => {
                        onRemoveProduct(index);
                      }}
                      size="sm"
                      floating
                      color="red"
                    >
                      <MDBIcon icon="times" />
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              );
            })}

            <MDBRow className="border-bottom w-100">
              Suma kcal:{' '}
              {recipeProducts
                .map(item => {
                  const { value, product_id: productId } = item;
                  const product = items.find(({ id: ProdId }) => {
                    return ProdId === productId;
                  }) || {
                    kcal: 0,
                  };
                  return product.kcal * (value / 100);
                })
                .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
                .toFixed(2)}
            </MDBRow>

            {micronutrients.map(({ id: idMap, namePL: namePLMap }) => (
              <MDBRow className="border-bottom w-100">
                Suma {`${namePLMap}: `}
                {recipeProducts
                  .map(({ value, product_id: productId }) => {
                    const product = items.find(({ id: ProdId }) => {
                      return ProdId === productId;
                    }) || {
                      micronutrients: [],
                    };
                    return (
                      parseFloat(
                        (
                          product.micronutrients.filter(
                            ({ id: micronutrientsId }) =>
                              parseInt(idMap, 10) === parseInt(micronutrientsId, 10),
                          )[0] || { value: 0 }
                        ).value,
                      ) *
                      (value / 100)
                    );
                  })
                  .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
                  .toFixed(2)}
              </MDBRow>
            ))}

            <MDBBtnFixed
              floating
              size="lg"
              icon="plus"
              gradient="blue"
              style={{ bottom: '45px', right: '24px' }}
              onClick={() => {
                onAddProduct();
              }}
            />
          </MDBTabPane>
        </MDBTabContent>

        <MDBBtn type="submit"> Zapisz </MDBBtn>
      </form>
    );
  }
}

ProductForm.defaultProps = {};

ProductForm.propTypes = {};

const mapStateToProps = state => {
  return {
    token: state.token,
  };
};

export default connect(mapStateToProps, {})(ProductForm);
