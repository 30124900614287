import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { toast } from "mdbreact";

export default function(ComposedComponent, permission) {
  class HasAccess extends Component {
    PropTypes = {
      router: PropTypes.object,
    };

    componentWillMount() {
      const { permissions, history } = this.props;
      if (!permissions.includes(permission)) {
        toast.warn("Nie masz dostępu do tego zasobu");
        history.push("/");
      }
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }
  function mapStateToProps(state) {
    return { permissions: state.permissions };
  }
  return connect(mapStateToProps)(HasAccess);
}
