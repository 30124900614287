import React from "react";
import { MDBCol, MDBInput, MDBRow } from "mdbreact";
import { Field } from "react-final-form";

export const PatientData = () => {
  return (
    <MDBRow>
      <MDBCol lg={4}>
      <Field
        name="name"
        type="text"
        label="Imię"
        render={({ input, meta, ...rest }) => (
          <MDBInput
            className={meta.touched && meta.error ? 'is-invalid' : ''}
            {...input}
            {...rest}
          >
            {meta.touched && meta.error && (
              <div className="invalid-feedback">{meta.error}</div>
            )}
          </MDBInput>
        )}
      />

        <Field
          name="lastname"
          type="text"
          label="Nazwisko"
          render={({ input, meta, ...rest }) => (
            <MDBInput
              className={meta.touched && meta.error ? 'is-invalid' : ''}
              {...input}
              {...rest}
            >
              {meta.touched && meta.error && (
                <div className="invalid-feedback">{meta.error}</div>
              )}
            </MDBInput>
          )}
        />

        <Field
          name="weight"
          type="number"
          step="0.1"
          min="1"
          label="Waga"
          render={({ input, meta, ...rest }) => (
            <MDBInput
              className={meta.touched && meta.error ? 'is-invalid' : ''}
              {...input}
              {...rest}
            >
              {meta.touched && meta.error && (
                <div className="invalid-feedback">{meta.error}</div>
              )}
            </MDBInput>
          )}
        />


        <Field
          name="height"
          type="number"
          min="1"
          label="Wzrost"
          render={({ input, meta, ...rest }) => (
            <MDBInput
              className={meta.touched && meta.error ? 'is-invalid' : ''}
              {...input}
              {...rest}
            >
              {meta.touched && meta.error && (
                <div className="invalid-feedback">{meta.error}</div>
              )}
            </MDBInput>
          )}
        />

        <Field
          name="age"
          type="number"
          min="1"
          label="Wiek"
          render={({ input, meta, ...rest }) => (
            <MDBInput
              className={meta.touched && meta.error ? 'is-invalid' : ''}
              {...input}
              {...rest}
            >
              {meta.touched && meta.error && (
                <div className="invalid-feedback">{meta.error}</div>
              )}
            </MDBInput>
          )}
        />

        <Field
          name="dishes"
          type="number"
          min="4"
          max="5"
          label="Ilość posiłków"
          render={({ input, meta, ...rest }) => (
            <MDBInput
              className={meta.touched && meta.error ? 'is-invalid' : ''}
              {...input}
              {...rest}
            >
              {meta.touched && meta.error && (
                <div className="invalid-feedback">{meta.error}</div>
              )}
            </MDBInput>
          )}
        />
      </MDBCol>
      <MDBCol lg={4}>
        <Field
          name="dishDescription"
          type="textarea"
          label="Rozkład Posiłków:"
          render={({ input, meta, ...rest }) => (
            <MDBInput
              className={meta.touched && meta.error ? 'is-invalid h-100' : 'h-100'}
              containerClass="h-100"
              {...input}
              {...rest}
            >
              {meta.touched && meta.error && (
                <div className="invalid-feedback">{meta.error}</div>
              )}
            </MDBInput>
          )}
        />
      </MDBCol>
    </MDBRow>
  );
};
