import React, { Component } from "react";
import {
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBRow,
  MDBTabContent,
  MDBTabPane,
  MDBBtn
} from "mdbreact";
import { connect } from "react-redux";

class VideoForm extends Component {
  state = {
    activeItem: "1"
  };

  componentDidMount() {
  }

  toggle = tab => {
    const { activeItem } = this.state;
    if (activeItem !== tab) {
      this.setState({
        activeItem: tab
      });
    }
  };

  render() {
    const { activeItem } = this.state;
    const { form, onSubmit, onChange } = this.props;
    const { title:name, description, url } = form.data;
    const { errors } = form;

    const {
      title: nameError,
      description: descriptionError,
      url: urlError
    } = errors;

    return (
      <form onSubmit={onSubmit} id="form">
        <MDBNav tabs className="my-3 dark-purple">
          <MDBNavItem>
            <MDBNavLink
              active={activeItem === "1"}
              onClick={e => {
                e.preventDefault();
                this.toggle("1");
              }}
              role="tab"
              to="/tab1"
            >
              <MDBIcon icon="database"/> Dane
            </MDBNavLink>
          </MDBNavItem>
        </MDBNav>
        <MDBTabContent activeItem={activeItem}>
          <MDBTabPane tabId="1" role="tabpanel">
            <MDBRow>
              <MDBCol lg={6}>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Nazwa"
                      name="title"
                      className={nameError === undefined ? "" : "is-invalid"}
                      value={name}
                      type="textarea"
                      onChange={onChange}
                    >
                      {nameError !== undefined && (
                        <div className="invalid-feedback">{nameError[0]}</div>
                      )}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>


                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="Opis"
                      name="description"
                      className={descriptionError === undefined ? "" : "is-invalid"}
                      value={description}
                      type="textarea"
                      onChange={onChange}
                    >
                      {descriptionError !== undefined && (
                        <div className="invalid-feedback">{descriptionError[0]}</div>
                      )}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="ID filmu Youtube"
                      name="url"
                      className={urlError === undefined ? "" : "is-invalid"}
                      value={url}
                      type="textarea"
                      onChange={onChange}
                    >
                      {urlError !== undefined && (
                        <div className="invalid-feedback">{urlError[0]}</div>
                      )}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>

              </MDBCol>

            </MDBRow>
          </MDBTabPane>
        </MDBTabContent>

        <MDBBtn type="submit">Zapisz</MDBBtn>
      </form>
    );
  }
}

VideoForm.defaultProps = {};

VideoForm.propTypes = {};

const mapStateToProps = state => {
  return {
    token: state.token
  };
};

export default connect(mapStateToProps, {})(VideoForm);
