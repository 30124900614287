import React, { useEffect, useState } from 'react';
import { PageTitle } from 'mdb-admin';
import { Field, Form } from 'react-final-form';
import axios from 'axios';
import {
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBRow,
  MDBSelect,
  MDBTabContent,
  MDBTabPane,
  toast,
} from 'mdbreact';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import styled from 'styled-components';
import PanelTemplate from '../../templates/PanelTemplate';
import { PatientData } from './PatientData';
import { mainURL } from '../../actions';
import { CommentData } from './CommentData';

const Grid = styled.div`
display: grid;
grid-template-columns: 200px auto;
    align-items: center;
}
`;

export const DietEdit = () => {
  const { token } = useSelector(state => state);
  const { id } = useParams();
  const [activeItem, setActiveItem] = useState('1');
  const [diet, setDiet] = useState({ dishesInfo: [], allDishes: [] });
  const [recipes, setRecipes] = useState([]);

  useEffect(() => {
    axios
      .get(`${mainURL}admin/diets/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data: { diet: newDiet } }) => {
        setDiet(newDiet);
      })
      .catch(error => {
        console.error(error);
      });
  }, [token, id]);

  useEffect(() => {
    axios
      .get(`${mainURL}admin/recipes/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data: { recipes: newRecipes } }) => {
        setRecipes(newRecipes);
      })
      .catch(error => {
        console.error(error);
      });
  }, [token]);

  return (
    <>
      <PanelTemplate>
        <PageTitle>Edytuj dietę</PageTitle>
        <Form
          mutators={{
            // potentially other mutators could be merged here
            ...arrayMutators,
          }}
          onSubmit={(values, form) => {
            axios
              .put(`${mainURL}admin/diets/${id}`, values, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .then(({ data: { diet: newDiet } }) => {
                form.reset(newDiet);
                toast.success('Zaktualizowano dane');
              })
              .catch(error => {
                toast.error('Błąd - spróbuj ponownie');
              });
          }}
          initialValues={diet}
          render={({ handleSubmit, form, values }) => (
            <form onSubmit={handleSubmit}>
              <MDBNav tabs className="my-3 dark-purple">
                <MDBNavItem>
                  <MDBNavLink
                    active={activeItem === '1'}
                    onClick={e => {
                      e.preventDefault();
                      setActiveItem('1');
                    }}
                    role="tab"
                    to="/tab1"
                  >
                    <MDBIcon icon="database" /> Dane
                  </MDBNavLink>
                </MDBNavItem>

                <MDBNavItem>
                  <MDBNavLink
                    active={activeItem === '2'}
                    onClick={e => {
                      e.preventDefault();
                      setActiveItem('2');
                    }}
                    role="tab"
                    to="/tab2"
                  >
                    <MDBIcon icon="database" /> Komentarze
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink
                    active={activeItem === '3'}
                    onClick={e => {
                      e.preventDefault();
                      setActiveItem('3');
                    }}
                    role="tab"
                    to="/tab3"
                  >
                    <MDBIcon icon="database" /> Posiłki
                  </MDBNavLink>
                </MDBNavItem>

                <MDBNavItem>
                  <MDBNavLink
                    active={activeItem === '4'}
                    onClick={e => {
                      e.preventDefault();
                      setActiveItem('4');
                    }}
                    role="tab"
                    to="/tab4"
                  >
                    <MDBIcon icon="database" /> Przepisy
                  </MDBNavLink>
                </MDBNavItem>
              </MDBNav>
              <MDBTabContent activeItem={activeItem}>
                <MDBTabPane tabId="1" role="tabpanel">
                  <PatientData />
                </MDBTabPane>
                <MDBTabPane tabId="2" role="tabpanel">
                  <CommentData />
                </MDBTabPane>
                <MDBTabPane tabId="3" role="tabpanel">
                  <FieldArray name="dishesInfo">
                    {({ fields }) => (
                      <div>
                        {fields.map(name => (
                          <div key={name}>
                            <Field
                              name={`${name}.dishNumber`}
                              render={({ input }) => <p>Posiłek {input.value}</p>}
                            />
                            <Field
                              name={`${name}.protein`}
                              render={({ input }) => <p>Białko: {input.value}g</p>}
                            />
                            <Field
                              name={`${name}.carbohydrates`}
                              render={({ input }) => <p>Węglowodany: {input.value}g</p>}
                            />
                            <Field
                              name={`${name}.fat`}
                              render={({ input }) => <p>Tłuszcze: {input.value}g</p>}
                            />

                            <Field
                              name={`${name}.description`}
                              type="textarea"
                              label="Komentarz"
                              render={({ input, meta, ...rest }) => (
                                <MDBInput
                                  className={meta.touched && meta.error ? 'is-invalid' : ''}
                                  {...input}
                                  {...rest}
                                >
                                  {meta.touched && meta.error && (
                                    <div className="invalid-feedback">{meta.error}</div>
                                  )}
                                </MDBInput>
                              )}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </FieldArray>
                </MDBTabPane>

                <MDBTabPane tabId="4" role="tabpanel">
                  <FieldArray name="allDishes">
                    {({ fields }) => (
                      <>
                        {fields.map(name => (
                          <Grid>
                            <Field
                              name={`${name}.dish`}
                              render={({ input }) => <span>Posiłek {input.value}</span>}
                            />
                            <FieldArray name={`${name}.dishes`}>
                              {({ fields: innerFields }) => (
                                <>
                                  <MDBBtn
                                    onClick={() => {
                                      innerFields.push();
                                    }}
                                    size="sm"
                                    color="blue"
                                    rounded
                                    floating
                                  >
                                    <MDBIcon icon="plus" />
                                  </MDBBtn>
                                  <div>
                                    {innerFields.map((innerName, index) => (
                                      <MDBRow>
                                        <Field
                                          name={innerName}
                                          render={({ input }) => (
                                            <MDBSelect
                                              options={[
                                                ...recipes.map(selectItem => {
                                                  return {
                                                    text: `${selectItem.name}`,
                                                    value: selectItem.id,
                                                    checked: selectItem.id === input.value,
                                                  };
                                                }),
                                              ]}
                                              selected="Wybierz przepis"
                                              label="Wybierz przepis"
                                              getValue={selectValue => {
                                                if (input.value !== selectValue[0]) {
                                                  form.change(input.name, selectValue[0]);
                                                }
                                              }}
                                            />
                                          )}
                                        />
                                        <MDBBtn
                                          onClick={() => {
                                            innerFields.remove(index);
                                          }}
                                          size="sm"
                                          floating
                                          color="red"
                                        >
                                          <MDBIcon icon="times" />
                                        </MDBBtn>
                                      </MDBRow>
                                    ))}
                                  </div>
                                </>
                              )}
                            </FieldArray>
                          </Grid>
                        ))}
                      </>
                    )}
                  </FieldArray>
                </MDBTabPane>
              </MDBTabContent>
              <MDBBtn type="submit">Zapisz</MDBBtn>
            </form>
          )}
        />
      </PanelTemplate>
    </>
  );
};
