import React, { useEffect, useState } from 'react';
import { PageTitle } from 'mdb-admin';
import {
  MDBBtn,
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBPopover,
  MDBPopoverBody,
  MDBPopoverHeader,
  MDBRow,
  MDBTabContent,
  MDBTabPane,
  toast,
} from 'mdbreact';
import axios from 'axios';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PanelTemplate from '../../templates/PanelTemplate';
import { mainURL } from '../../actions';
import { HasAccess } from '../../hooks/HasAccess';

const StyledList = styled.div`
  display: flex;
  flex-direction: row;
  transition: transform 0.4s ease-in-out;
  transform: translateX(-${({ index }) => index * 100}%);
  > * {
    flex-shrink: 0;
    width: 100%;
  }
`;

const StyledMDBCol = styled(MDBCol)`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 10px;
`;
const ListItem = ({
  id,
  namePL,
  nameEN,
  nameSPA,
  descriptionPL,
  descriptionEN,
  descriptionSPA,
  meals,
  url,
  recipe_products: recipeProducts,
  micronutrients,
  products,
  onAccept,
  onDelete,
}) => {
  const [activeItem, setActiveItem] = useState('1');
  const token = useSelector(state => state.token);

  const deleteRecipe = () => {
    axios
      .delete(`${mainURL}admin/recipes/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => {
        toast.success('Usunięto przepis');
        onDelete();
      })
      .catch(e => {
        toast.error(e.response.data.message);
      });
  };

  return (
    <div>
      <MDBNav tabs className="my-3 dark-purple">
        <MDBNavItem>
          <MDBNavLink
            onClick={() => setActiveItem('1')}
            role="tab"
            active={activeItem === '1'}
            to="#"
          >
            <MDBIcon icon="database" /> Dane
          </MDBNavLink>
        </MDBNavItem>
        <MDBNavItem>
          <MDBNavLink
            onClick={() => setActiveItem('2')}
            role="tab"
            active={activeItem === '2'}
            to="#"
          >
            <MDBIcon icon="database" /> Produkty
          </MDBNavLink>
        </MDBNavItem>
      </MDBNav>
      <MDBTabContent activeItem={activeItem}>
        <MDBTabPane tabId="1" role="tabpanel">
          <MDBRow>
            <MDBCol>
              <MDBInput label="Nazwa PL" name="namePL" value={namePL} disabled="disabled" />
            </MDBCol>

            <MDBCol>
              <MDBInput label="Nazwa ENG" name="nameEN" value={nameEN} disabled="disabled" />
            </MDBCol>

            <MDBCol>
              <MDBInput label="Nazwa SPA" name="nameSPA" value={nameSPA} disabled="disabled" />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol>
              <MDBInput
                label="Opis PL"
                name="descriptionPL"
                value={descriptionPL}
                disabled="disabled"
              />
            </MDBCol>

            <MDBCol>
              <MDBInput
                label="Opis ENG"
                name="descriptionEN"
                value={descriptionEN}
                disabled="disabled"
              />
            </MDBCol>
            <MDBCol>
              <MDBInput
                label="Opis SPA"
                name="descriptionSPA"
                value={descriptionSPA}
                disabled="disabled"
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol lg={6}>
              <MDBInput
                label="Wybierz posiłki"
                value={meals.map(meal => `${meal.value}, `)}
                disabled="disabled"
              />
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol lg={6}>{url && <img src={url} alt="" className="w-100" />}</MDBCol>
          </MDBRow>
        </MDBTabPane>
        <MDBTabPane tabId="2" role="tabpanel">
          {recipeProducts.map((item, index) => {
            const { value, product_id: productId } = item;
            const product = products.find(({ id: ProdId }) => {
              return ProdId === productId;
            }) || {
              micronutrients: [],
            };
            return (
              <MDBRow className="border-bottom w-100">
                <MDBCol size={2}>
                  <MDBInput label="Wybierz produkt" value={product.namePL} disabled="disabled" />
                </MDBCol>
                <StyledMDBCol>
                  <MDBInput
                    label="Gramy"
                    name={`recipe_products__${index}__value`}
                    value={value}
                    type="number"
                    step="0.01"
                    min={0}
                    disabled="disabled"
                  />
                  {micronutrients.map(micronutrient => {
                    const micronutrientsIndex = product.micronutrients?.findIndex(elem => {
                      return elem.id === micronutrient.id;
                    });
                    return (
                      <MDBInput
                        label={micronutrient.namePL}
                        value={
                          micronutrientsIndex > -1
                            ? product.micronutrients[micronutrientsIndex].id
                            : 0
                        }
                        disabled={1}
                      />
                    );
                  })}
                </StyledMDBCol>
              </MDBRow>
            );
          })}
        </MDBTabPane>
      </MDBTabContent>
      <MDBBtn type="button" onClick={onAccept}>
        Akceptuj
      </MDBBtn>
      <MDBBtn tag="a" role="button" href={`/przepisy/${id}`}>
        Edytuj
      </MDBBtn>
      <MDBPopover placement="right" popover clickable id="popper4">
        <MDBBtn title="Usuń" pay>
          <MDBIcon icon="trash-alt" />
        </MDBBtn>
        <div>
          <MDBPopoverHeader>Na pewno usunąć?</MDBPopoverHeader>
          <MDBPopoverBody>
            <MDBBtn title="Usuń" pay onClick={deleteRecipe}>
              <MDBIcon icon="trash-alt" />
            </MDBBtn>
          </MDBPopoverBody>
        </div>
      </MDBPopover>
    </div>
  );
};

export const AcceptList = () => {
  HasAccess('recipes-accept');
  const [index, setIndex] = useState(0);
  const [changed, setChanged] = useState(0);
  const [products, setProducts] = useState([]);
  const [micronutrients, setMicronutrients] = useState([]);

  const [recipies, setRecipies] = useState([]);
  const token = useSelector(state => state.token);

  useEffect(() => {
    axios
      .get(`${mainURL}admin/products`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        setProducts(response.data.products);
      })
      .catch(() => {
        // console.error(error);
      });

    axios
      .get(`${mainURL}admin/micronutrients`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        setMicronutrients(response.data.micronutrients);
      })
      .catch(error => {
        console.error(error);
      });

    axios
      .get(`${mainURL}admin/recipes/accept`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        setRecipies(response.data.recipes);
      })
      .catch(error => {
        console.error(error);
      });
  }, [changed, token]);

  const accept = id => {
    axios
      .put(
        `${mainURL}admin/recipes/toggle/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(() => {
        setChanged(changed + 1);
      })
      .catch(error => {
        console.error(error);
      });
  };

  return (
    <PanelTemplate pageTitle="">
      <PageTitle>Lista przepisów do akceptacji</PageTitle>
      <StyledList index={index}>
        {recipies.map(recipie => (
          <ListItem
            {...recipie}
            products={products}
            micronutrients={micronutrients}
            onAccept={() => {
              accept(recipie.id);
            }}
            onDelete={() => {
              setChanged(changed + 1);
            }}
          />
        ))}
      </StyledList>

      <MDBBtn
        type="button"
        onClick={() => {
          setIndex(index - 1);
        }}
        disabled={!index}
      >
        {' '}
        Poprzednie{' '}
      </MDBBtn>

      <MDBBtn
        type="button"
        onClick={() => {
          setIndex(index + 1);
        }}
        disabled={recipies.length === index + 1}
      >
        {' '}
        Nastęne{' '}
      </MDBBtn>
    </PanelTemplate>
  );
};
