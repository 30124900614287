import React, { Component } from 'react';
import axios from 'axios';
import { DataTable, PageTitle, NewResourceButton } from 'mdb-admin';
import { MDBBtn, MDBIcon, toast, MDBModal, MDBModalFooter, MDBModalHeader } from 'mdbreact';
import { connect } from 'react-redux';
import PanelTemplate from '../../templates/PanelTemplate';
import { deleteProductAction, mainURL } from '../../actions';
import hasAccess from "../../middlewares/hasAccess";
import axiosClient from "../../api/axiosClient";

class ProductList extends Component {
  state = {
    items: [],
    modal: false,
  };

  componentDidMount() {
    this.fetch();
  }

  fetch = () => {
    const { token } = this.props;
    axiosClient
      .get(`${mainURL}admin/products`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        const { products } = response.data;

        this.setState({
          items: products,
        });
      })
      .catch(error => {
        console.error(error);
      });
  };

  delete = id => {
    const { deleteItem, token } = this.props;
    deleteItem(token, id).then(response => {
      this.toggle();
      if (response === 204) {
        toast.success('Usunięto produkt');
        this.fetch();
      } else {
        toast.error(response);
      }
    });
  };

  toggle = (modal = null) => {
    this.setState({
      modal,
    });
  };

  render() {
    const { items, modal } = this.state;
    const data = {
      columns: [
        {
          label: 'ID',
          field: 'id',
          sort: 'asc',
        },
        {
          label: 'namePL',
          field: 'namePL',
          sort: 'asc',
        },
        {
          label: 'nameEN',
          field: 'nameEN',
          sort: 'asc',
        },
        {
          label: 'Akcje',
          field: 'actions',
          sort: 'disabled',
        },
      ],
      rows: items.map(item => {
        const { id, namePL, nameEN } = item;
        return {
          id,
          namePL,
          nameEN,
          actions: (
            <>
              <MDBBtn
                title="Edytuj"
                as="a"
                href={`/produkty/${id}`}
                className="btn btn-default Ripple-parent"
              >
                Edytuj
              </MDBBtn>

              <MDBBtn
                title="Usuń"
                pay
                onClick={() => {
                  this.toggle(id);
                }}
              >
                <MDBIcon icon="trash-alt" />
              </MDBBtn>
              <MDBModal isOpen={modal === id} toggle={this.toggle}>
                <MDBModalHeader toggle={this.toggle}>Usunąć?</MDBModalHeader>
                <MDBModalFooter>
                  <MDBBtn className="btn-default" onClick={this.toggle}>
                    Anuluj
                  </MDBBtn>
                  <MDBBtn
                    className="btn-default"
                    onClick={() => {
                      this.delete(id);
                    }}
                  >
                    Usuń
                  </MDBBtn>
                </MDBModalFooter>
              </MDBModal>
            </>
          ),
        };
      }),
    };

    return (
      <PanelTemplate pageTitle="">
        <PageTitle>Lista produktów</PageTitle>
        <NewResourceButton path="/produkty/dodaj">Dodaj</NewResourceButton>
        <DataTable data={data} />
      </PanelTemplate>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.token,
  };
};

export default connect(
  mapStateToProps,
  {
    deleteItem: deleteProductAction,
  },
)(hasAccess(ProductList,"products-list"));
