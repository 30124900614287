// eslint-disable-next-line import/no-cycle
import axios from 'axios';

export const FETCH_ERROR = 'FETCH_ERROR';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export const mainURL = `${process.env.REACT_APP_API_URL}`;
export const baseURL = `${process.env.REACT_APP_BASE_URL}`;

export function logInAction(email, password) {
  return async dispatch => {
    try {
      const res = await axios.post(`${mainURL}login`, { email, password });
      const { access_token: accessToken, permissions } = res.data;
      dispatch({
        type: LOGIN,
        token: accessToken,
        permissions: [
          ...permissions.map(({ name: mainName, details }) =>
            details.map(({ name }) => `${mainName}-${name}`),
          ),
        ]
          .reduce((a, b) => a.concat(b))
          .join(),
      });
    } catch (error) {
      dispatch({
        type: FETCH_ERROR,
        payload: 'Niepoprawny email lub hasło',
      });
    }
  };
}

export function logOutAction(/* token */) {
  return async dispatch => {
    try {
      dispatch({ type: LOGOUT });
    } catch (error) {
      dispatch({
        type: FETCH_ERROR,
        payload: 'Niepoprawny email lub hasło',
      });
    }
  };
}

export function createAdminAction(token, data = {}) {
  return async () => {
    try {
      const res = await axios.post(`${mainURL}admin/admins`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const { admin } = res.data;
      return admin;
      // dispatch({ type: GETNEWS, news: res.data.news });
    } catch (error) {
      return error.response.data.errors;
    }
  };
}

export function getAdminAction(token, id, params = {}) {
  return async () => {
    try {
      const res = await axios.get(`${mainURL}admin/admins/${id}`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });
      const { admin } = res.data;
      return admin;
    } catch (error) {
      return [];
    }
  };
}

export function updateAdminAction(token, id, data = {}) {
  return async () => {
    try {
      const res = await axios.put(`${mainURL}admin/admins/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const { admin } = res.data;
      return admin;
      // dispatch({ type: GETNEWS, news: res.data.news });
    } catch (error) {
      return error.response.data.errors;
    }
  };
}

export function deleteAdminAction(token, id) {
  return async () => {
    try {
      const res = await axios.delete(`${mainURL}admin/admins/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return res.status;
    } catch (error) {
      return error.response.data.message;
    }
  };
}
export function createVideoAction(token, data = {}) {
  return async () => {
    try {
      const res = await axios.post(`${mainURL}admin/videos`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { video } = res.data;
      return video;
      // dispatch({ type: GETNEWS, news: res.data.news });
    } catch (error) {
      return error.response.data.errors;
    }
  };
}

export function deleteVideoAction(token, id) {
  return async () => {
    try {
      const res = await axios.delete(`${mainURL}admin/videos/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return res.status;
    } catch (error) {
      return error.response.data.message;
    }
  };
}

export function getVideoAction(token, id, params = {}) {
  return async () => {
    try {
      const res = await axios.get(`${mainURL}admin/videos/${id}`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });
      const { video } = res.data;
      return video;
    } catch (error) {
      return [];
    }
  };
}

export function updateVideoAction(token, id, data = {}) {
  return async () => {
    try {
      const res = await axios.post(`${mainURL}admin/videos/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const { video } = res.data;
      return video;
      // dispatch({ type: GETNEWS, news: res.data.news });
    } catch (error) {
      return error.response.data.errors;
    }
  };
}

export function deleteInfoAction(token, id) {
  return async () => {
    try {
      const res = await axios.delete(`${mainURL}admin/information/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return res.status;
    } catch (error) {
      return error.response.data.message;
    }
  };
}

export function createInfoAction(token, data = {}) {
  return async () => {
    try {
      const res = await axios.post(`${mainURL}admin/information`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const { information } = res.data;
      return information;
      // dispatch({ type: GETNEWS, news: res.data.news });
    } catch (error) {
      return error.response.data.errors;
    }
  };
}

export function getInfoAction(token, id, params = {}) {
  return async () => {
    try {
      const res = await axios.get(`${mainURL}admin/information/${id}`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });
      const { information } = res.data;
      return information;
    } catch (error) {
      return [];
    }
  };
}

export function updateInfoAction(token, id, data = {}) {
  return async () => {
    try {
      const res = await axios.post(`${mainURL}admin/information/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const { information } = res.data;
      return information;
      // dispatch({ type: GETNEWS, news: res.data.news });
    } catch (error) {
      return error.response.data.errors;
    }
  };
}

export function deleteRegulationAction(token, id) {
  return async () => {
    try {
      const res = await axios.delete(`${mainURL}admin/regulation/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return res.status;
    } catch (error) {
      return error.response.data.message;
    }
  };
}

export function createRegulationAction(token, data = {}) {
  return async () => {
    try {
      const res = await axios.post(`${mainURL}admin/regulation`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const { regulation } = res.data;
      return regulation;
    } catch (error) {
      return error.response.data.errors;
    }
  };
}

export function getRegulationAction(token, id, params = {}) {
  return async () => {
    try {
      const res = await axios.get(`${mainURL}admin/regulation/${id}`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });
      const { regulation } = res.data;
      return regulation;
    } catch (error) {
      return [];
    }
  };
}

export function updateRegulationAction(token, id, data = {}) {
  return async () => {
    try {
      const res = await axios.post(`${mainURL}admin/regulation/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const { regulation } = res.data;
      return regulation;
    } catch (error) {
      return error.response.data.errors;
    }
  };
}

export function deleteQuestionAction(token, id) {
  return async () => {
    try {
      const res = await axios.delete(`${mainURL}admin/question/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return res.status;
    } catch (error) {
      return error.response.data.message;
    }
  };
}

export function createQuestionAction(token, data = {}) {
  return async () => {
    try {
      const res = await axios.post(`${mainURL}admin/question`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const { question } = res.data;
      return question;
      // dispatch({ type: GETNEWS, news: res.data.news });
    } catch (error) {
      return error.response.data.errors;
    }
  };
}

export function getQuestionAction(token, id, params = {}) {
  return async () => {
    try {
      const res = await axios.get(`${mainURL}admin/question/${id}`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });

      const { question } = res.data;
      return question;
    } catch (error) {
      return [];
    }
  };
}

export function updateQuestionAction(token, id, data = {}) {
  return async () => {
    try {
      const res = await axios.put(`${mainURL}admin/question/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const { question } = res.data;
      return question;
      // dispatch({ type: GETNEWS, news: res.data.news });
    } catch (error) {
      return error.response.data.errors;
    }
  };
}

export function getMedicineAction(token, id, params = {}) {
  return async () => {
    try {
      const res = await axios.get(`${mainURL}admin/medicine/${id}`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });

      const { medicine } = res.data;
      return medicine;
    } catch (error) {
      return [];
    }
  };
}

export function getCompilationAction(token, id, params = {}) {
  return async () => {
    try {
      const res = await axios.get(`${mainURL}admin/complication/${id}`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });

      const { complication } = res.data;
      return complication;
    } catch (error) {
      return [];
    }
  };
}

export function updateMedicineAction(token, id, data = {}) {
  return async () => {
    try {
      const res = await axios.put(`${mainURL}admin/medicine/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const { medicine } = res.data;
      return medicine;
      // dispatch({ type: GETNEWS, news: res.data.news });
    } catch (error) {
      return error.response.data.errors;
    }
  };
}

export function updateCompilationAction(token, id, data = {}) {
  return async () => {
    try {
      const res = await axios.put(`${mainURL}admin/complication/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const { complication } = res.data;
      return complication;
    } catch (error) {
      return error.response.data.errors;
    }
  };
}

export function deleteMedicineAction(token, id) {
  return async () => {
    try {
      const res = await axios.delete(`${mainURL}admin/medicine/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return res.status;
    } catch (error) {
      return error.response.data.message;
    }
  };
}

export function deleteCompilationAction(token, id) {
  return async () => {
    try {
      const res = await axios.delete(`${mainURL}admin/complication/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return res.status;
    } catch (error) {
      return error.response.data.message;
    }
  };
}

export function createMedicineAction(token, data = {}) {
  return async () => {
    try {
      const res = await axios.post(`${mainURL}admin/medicine`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const { medicine } = res.data;
      return medicine;
      // dispatch({ type: GETNEWS, news: res.data.news });
    } catch (error) {
      return error.response.data.errors;
    }
  };
}

export function createCompilationAction(token, data = {}) {
  return async () => {
    try {
      const res = await axios.post(`${mainURL}admin/complication`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const { complication } = res.data;
      return complication;
    } catch (error) {
      return error.response.data.errors;
    }
  };
}

export function deleteUserAction(token, id) {
  return async () => {
    try {
      const res = await axios.delete(`${mainURL}admin/users/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return res.status;
    } catch (error) {
      return error.response.data.message;
    }
  };
}

export function deleteMicronutrientAction(token, id) {
  return async () => {
    try {
      const res = await axios.delete(`${mainURL}admin/micronutrients/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return res.status;
    } catch (error) {
      return error.response.data.message;
    }
  };
}
export function deleteProductAction(token, id) {
  return async () => {
    try {
      const res = await axios.delete(`${mainURL}admin/products/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return res.status;
    } catch (error) {
      return error.response.data.message;
    }
  };
}
export function deleteRecipeAction(token, id) {
  return async () => {
    try {
      const res = await axios.delete(`${mainURL}admin/recipes/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return res.status;
    } catch (error) {
      return error.response.data.message;
    }
  };
}

export function createMicronutrientAction(token, data = {}) {
  return async () => {
    try {
      const res = await axios.post(`${mainURL}admin/micronutrients`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const { micronutrient } = res.data;
      return micronutrient;
    } catch (error) {
      return error.response.data.errors;
    }
  };
}
export function createProductAction(token, data = {}) {
  return async () => {
    try {
      const res = await axios.post(`${mainURL}admin/products`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const { product } = res.data;
      return product;
    } catch (error) {
      console.log(error.response);

      return error.response.data.errors;
    }
  };
}
export function createRecipeAction(token, data = {}) {
  return async () => {
    try {
      const res = await axios.post(`${mainURL}admin/recipes`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const { recipe } = res.data;
      return recipe;
    } catch (error) {
      console.log(error.response);

      return error.response.data.errors;
    }
  };
}

export function getMicronutrientAction(token, id, params = {}) {
  return async () => {
    try {
      const res = await axios.get(`${mainURL}admin/micronutrients/${id}`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });

      const { micronutrient } = res.data;
      return micronutrient;
    } catch (error) {
      return [];
    }
  };
}

export function getProductAction(token, id, params = {}) {
  return async () => {
    try {
      const res = await axios.get(`${mainURL}admin/products/${id}`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });

      const { product } = res.data;
      return product;
    } catch (error) {
      return [];
    }
  };
}

export function getRecipeAction(token, id, params = {}) {
  return async () => {
    try {
      const res = await axios.get(`${mainURL}admin/recipes/${id}`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });

      const { recipe } = res.data;
      return recipe;
    } catch (error) {
      return [];
    }
  };
}

export function updateMicronutrientAction(token, id, data = {}) {
  return async () => {
    try {
      const res = await axios.put(`${mainURL}admin/micronutrients/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const { micronutrient } = res.data;
      return micronutrient;
    } catch (error) {
      return error.response.data.errors;
    }
  };
}

export function updateProductAction(token, id, data = {}) {
  return async () => {
    try {
      const res = await axios.put(`${mainURL}admin/products/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const { product } = res.data;
      return product;
    } catch (error) {
      return error.response.data.errors;
    }
  };
}

export function updateRecipeAction(token, id, data = {}) {
  return async () => {
    try {
      const res = await axios.post(`${mainURL}admin/recipes/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const { recipe } = res.data;
      return recipe;
    } catch (error) {
      return error.response.data.errors;
    }
  };
}
