import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { toast } from 'mdbreact';
import Form from './Form';
import { createRecipeAction } from '../../actions';
import PanelTemplate from '../../templates/PanelTemplate';
import hasAccess from '../../middlewares/hasAccess';

class RecipeCreate extends Component {
  state = {
    form: {
      data: {
        recipe_products: [{ value: 100, product_id: null }],
        meals: [],
      },
      errors: {},
    },
    redirect: null,
  };

  handleChange = e => {
    const { form } = this.state;
    const { name, value } = e.target;
    const newForm = { ...form };

    const splited = name.split('__');

    if (splited.length === 3) {
      if (newForm.data[splited[0]][splited[1]]) {
        newForm.data[splited[0]][splited[1]][splited[2]] = value;
      } else {
        newForm.data[splited[0]][splited[1]] = {};
      }
    } else {
      newForm.data[name] = value;
    }

    this.setState({ form: newForm });
  };

  handleChangeName = (value, name) => {
    const { form } = this.state;
    const newForm = { ...form };

    const splited = name.split('__');

    if (splited.length === 3) {
      if (newForm.data[splited[0]][splited[1]]) {
        newForm.data[splited[0]][splited[1]][splited[2]] = value;
      } else {
        newForm.data[splited[0]][splited[1]] = {};
      }
    } else {
      newForm.data[name] = value;
    }

    this.setState({ form: newForm });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { createItem, token } = this.props;
    const { form } = this.state;
    const { data } = form;

    const fd = new FormData();

    Object.keys(data).forEach(key => {
      // eslint-disable-next-line no-unused-expressions
      Array.isArray(data[key])
        ? key === 'recipe_products'
          ? data[key].forEach((value, index) => {
              fd.append(`${key}[${index}][value]`, value.value);
              fd.append(`${key}[${index}][product_id]`, value.product_id);
            })
          : data[key].forEach((value, index) => {
              fd.append(`${key}[${index}][value]`, value.value);
            })
        : fd.append(key, data[key]);
    });

    createItem(token, fd).then(response => {
      if (response?.id) {
        toast.success('Dodano produkt.');
        setTimeout(() => {
          this.setState({ redirect: `/przepisy/do-akceptacji` });
        }, 2000);
      } else {
        toast.error('Nie udało się dodać nowego produktu. Sprawdź błędy.');
        const newForm = { ...form };
        newForm.errors = response;

        this.setState({ form: newForm });
      }
    });
  };

  addProduct = () => {
    const { form } = this.state;
    const { data } = form;
    const { recipe_products: recipeProducts } = data;
    const newForm = { ...form };
    const newRecipeProduct = [...recipeProducts];

    newRecipeProduct.push({
      value: 100,
      product_id: null,
    });

    newForm.data.recipe_products = newRecipeProduct;
    this.setState({ form: newForm });
  };

  removeProduct = itemIndex => {
    const { form } = this.state;
    const { data } = form;
    const { recipe_products: recipeProducts } = data;
    const newForm = { ...form };

    newForm.data.recipe_products = recipeProducts.filter((item, index) => {
      return index !== itemIndex;
    });
    this.setState({ form: newForm });
  };

  render() {
    const { form, redirect, date } = this.state;
    if (redirect) {
      return <Redirect to={redirect} />;
    }

    return (
      <PanelTemplate>
        <h3 className="py-2 color-dark-purple">Dodaj przepis</h3>
        <Form
          form={form}
          date={date}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
          onChangeName={this.handleChangeName}
          onAddProduct={this.addProduct}
          onRemoveProduct={this.removeProduct}
        />
      </PanelTemplate>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.token,
  };
};

export default connect(mapStateToProps, {
  createItem: createRecipeAction,
})(hasAccess(RecipeCreate, 'recipes-add'));
