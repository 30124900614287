import React, { Component } from "react";
import {
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBRow,
  MDBTabContent,
  MDBTabPane,
  MDBBtn
} from "mdbreact";
import { connect } from "react-redux";

class AdminForm extends Component {
  state = {
    activeItem: "1"
  };

  componentDidMount() {
  }

  toggle = tab => {
    const { activeItem } = this.state;
    if (activeItem !== tab) {
      this.setState({
        activeItem: tab
      });
    }
  };

  render() {
    const { activeItem } = this.state;
    const { form, onSubmit, onChange, onChangeName } = this.props;
    const { titlePL, titleEN, titleSPA, answers } = form.data;
    const { errors } = form;

    const { titlePL: titlePL1, titleEN: titleEN1, titleSPA: titleSPA1 } = errors;


    return (
      <form onSubmit={onSubmit} id="form">
        <MDBNav tabs className="my-3 dark-purple">
          <MDBNavItem>
            <MDBNavLink
              active={activeItem === "1"}
              onClick={e => {
                e.preventDefault();
                this.toggle("1")
              }}
              role="tab"
              to="/tab1"
            >
              <MDBIcon icon="database"/> Dane
            </MDBNavLink>
          </MDBNavItem>
        </MDBNav>
        <MDBTabContent activeItem={activeItem}>
          <MDBTabPane tabId="1" role="tabpanel">
            <MDBRow>
              <MDBCol lg={6}>

                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="titlePL"
                      name="titlePL"
                      className={titlePL1 === undefined ? "" : "is-invalid"}
                      value={titlePL}
                      onChange={onChange}
                    >
                      {titlePL1 !== undefined && (
                        <div className="invalid-feedback">{titlePL1[0]}</div>
                      )}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>


                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="titleEN"
                      name="titleEN"
                      className={titleEN1 === undefined ? "" : "is-invalid"}
                      value={titleEN}
                      onChange={onChange}
                    >
                      {titleEN1 !== undefined && (
                        <div className="invalid-feedback">{titleEN1[0]}</div>
                      )}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>


                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      label="titleSPA"
                      name="titleSPA"
                      className={titleSPA1 === undefined ? "" : "is-invalid"}
                      value={titleSPA}
                      onChange={onChange}
                    >
                      {titleSPA1 !== undefined && (
                        <div className="invalid-feedback">{titleSPA1[0]}</div>
                      )}
                    </MDBInput>
                  </MDBCol>
                </MDBRow>

                {answers.map((item, index) => {
                  const { titlePL: titlePLItem, titleEN: titleENItem, titleSPA: titleSPAItem, correct } = item;
                  return <MDBRow>

                    <MDBCol>
                      <MDBInput
                        label={`answers__${index}__titlePL`}
                        name={`answers__${index}__titlePL`}
                        value={titlePLItem}
                        onChange={onChange}

                        className={errors[`answers.${index}.titlePL`] === undefined ? "" : "is-invalid"}

                      >

                        {errors[`answers.${index}.titlePL`] !== undefined && (
                          <div className="invalid-feedback">{errors[`answers.${index}.titlePL`][0]}</div>
                        )}
                      </MDBInput>
                    </MDBCol>


                    <MDBCol>
                      <MDBInput
                        label={`answers__${index}__titleEN`}
                        name={`answers__${index}__titleEN`}
                        value={titleENItem}
                        onChange={onChange}
                        className={errors[`answers.${index}.titleEN`] === undefined ? "" : "is-invalid"}

                      >

                        {errors[`answers.${index}.titleEN`] !== undefined && (
                          <div className="invalid-feedback">{errors[`answers.${index}.titleEN`][0]}</div>
                        )}
                      </MDBInput>
                    </MDBCol>


                    <MDBCol>
                      <MDBInput
                        label={`answers__${index}__titleSPA`}
                        name={`answers__${index}__titleSPA`}
                        value={titleSPAItem}
                        onChange={onChange}
                        className={errors[`answers.${index}.titleSPA`] === undefined ? "" : "is-invalid"}

                      >

                        {errors[`answers.${index}.titleSPA`] !== undefined && (
                          <div className="invalid-feedback">{errors[`answers.${index}.titleSPA`][0]}</div>
                        )}
                      </MDBInput>
                    </MDBCol>

                    <MDBCol>
                      <MDBInput label="Poprawna" checked={correct} type="checkbox" id={`checkbox${index}`}
                                onChange={() => {
                                  onChangeName(!correct, `answers__${index}__correct`);
                                }}/>
                    </MDBCol>

                  </MDBRow>;
                })}

              </MDBCol>
            </MDBRow>
          </MDBTabPane>
        </MDBTabContent>

        <MDBBtn type="submit">
          Zapisz
        </MDBBtn>
      </form>
    );
  }
}

AdminForm.defaultProps = {};

AdminForm.propTypes = {};

const mapStateToProps = state => {
  return {
    token: state.token
  };
};

export default connect(
  mapStateToProps,
  {}
)(AdminForm);
