import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { toast } from "mdbreact";
import ServiceForm from "./Form";
import { createInfoAction } from "../../actions";
import PanelTemplate from "../../templates/PanelTemplate";
import hasAccess from "../../middlewares/hasAccess";

class InformationCreate extends Component {
  state = {
    form: {
      data: {},
      errors: {}
    },
    redirect: null
  };

  handleChange = e => {
    const { form } = this.state;
    const { name, value } = e.target;
    const newForm = { ...form };

    const splited = name.split("__");

    if (splited.length === 3) {
      if (newForm.data[splited[0]][splited[1]]) {
        newForm.data[splited[0]][splited[1]][splited[2]] = value;
      } else {
        newForm.data[splited[0]][splited[1]] = {};
      }
    } else {
      newForm.data[name] = value;
    }

    this.setState({ form: newForm });
  };

  handleChangeName = (value, name) => {
    const { form } = this.state;
    const newForm = { ...form };

    const splited = name.split("__");

    if (splited.length === 3) {
      if (newForm.data[splited[0]][splited[1]]) {
        newForm.data[splited[0]][splited[1]][splited[2]] = value;
      } else {
        newForm.data[splited[0]][splited[1]] = {};
      }
    } else {
      newForm.data[name] = value;
    }

    this.setState({ form: newForm });
  };

  handleVisibleChange = value => {
    this.setState(state => {
      const { form } = state;
      const newForm = { ...form };
      newForm.data.visible = value ? 1 : 0;
      return {
        form: newForm
      };
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { createItem, token } = this.props;
    const { form } = this.state;
    const { data } = form;

    const fd = new FormData();

    Object.keys(data).forEach(key => {
      fd.append(key, data[key]);
    });


    createItem(token, fd).then(response => {
      if (response.id) {
        toast.success("Dodano informację.");
        setTimeout(() => {
          this.setState({ redirect: `/informacje` });
        }, 2000);
      } else {
        toast.error("Nie udało się dodać nowej informacji. Sprawdź błędy.");
        const newForm = { ...form };
        newForm.errors = response;

        this.setState({ form: newForm });
      }
    });
  };

  handleVatChanged = value => {
    const { form } = this.state;
    const newForm = { ...form };
    newForm.data.vat_id = value;
    this.setState({ form: newForm });
  };

  addPrice = () => {
    const { form } = this.state;
    const { data } = form;
    const { prices } = data;
    const newForm = { ...form };
    const newPrices = [...prices];


    newPrices.push({
      price: 0,
      from: parseInt(new Date().getTime() / 1000, 0)
    });

    newForm.data.prices = newPrices;
    this.setState({ form: newForm });
  };

  handleDeletePrice = deleteIndex => {
    const { form } = this.state;
    const { data } = form;
    const { prices } = data;
    const newForm = { ...form };
    let newPrices = [...prices];

    newPrices = newPrices.filter((value, index) => {
      return deleteIndex !== index;
    });

    newForm.data.prices = newPrices;
    this.setState({ form: newForm });
  };




  render() {
    const { form, redirect, date } = this.state;
    if (redirect) {
      return <Redirect to={redirect}/>;
    }

    return (
      <PanelTemplate>
        <h3 className="py-2 color-dark-purple">Dodaj Informacje</h3>
        <ServiceForm
          form={form}
          date={date}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
          onVisibleChange={this.handleVisibleChange}
          onChangeName={this.handleChangeName}
          onVatChanged={this.handleVatChanged}
          onAddPrice={this.addPrice}
          onDeletePrice={this.handleDeletePrice}
        />
      </PanelTemplate>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.token
  };
};

export default connect(
  mapStateToProps,
  {
    createItem: createInfoAction
  }
)(hasAccess(InformationCreate,"informations-add"));
