import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { MDBInput } from 'mdbreact';
import { mainURL } from '../../actions';

export const Settlement = ({ id }) => {
  const [items, setItems] = useState([]);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const token = useSelector(state => state.token);

  useEffect(() => {
    axios
      .get(`${mainURL}admin/partners/${id}/settlements`, {
        params: {
          from,
          to,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data: { settlements } }) => {
        setItems(settlements);
      });
  }, [id, token, from, to]);

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <MDBInput
          containerClass="mr-5"
          label="od"
          type="date"
          onChange={e => {
            setFrom(e.target.value);
          }}
        />
        <MDBInput
          label="do"
          type="date"
          onChange={e => {
            setTo(e.target.value);
          }}
        />
      </div>
      {items.map(({ provision, created_at: createdAt, code }) => (
        <div>
          {createdAt} - kod: {code}- prowizja:{provision.toFixed(2)}zł
        </div>
      ))}
      <div>
        Suma:{' '}
        {parseInt(
          items.length
            ? items
                .map(({ provision }) => provision)
                .reduce((prev, item) => {
                  return prev + item;
                })
            : '0',
          10,
        ).toFixed(2)}
        zł
      </div>
    </div>
  );
};
